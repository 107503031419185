import {
	COLLATERAL_PYTH_IDS,
	SL_TP_MAX_SIZE_CROSS_MARGIN,
	SynthAssetKeysV3,
	V3_SYNTH_KEYS,
	ZERO_WEI,
	assetToTokenTicker,
} from '@kwenta/sdk/constants'
import {
	FuturesMarketAsset,
	type FuturesTrade,
	OrderTypeEnum,
	type PerpsMarketV3,
	PerpsProvider,
	type PerpsV3Liquidation,
	type PerpsV3Position,
	PositionSide,
	PotentialTradeStatus,
	SupportedNetworkIds,
	type TokenTickers,
} from '@kwenta/sdk/types'
import {
	DepositTokenToSynth,
	SwappableDepositOptions,
	calculateDesiredFillPrice,
	floorNumber,
	formatCurrency,
	formatOrderDisplayType,
	getDefaultPriceImpact,
	getDisplayAsset,
	isZero,
	orderTypeFromCondition,
} from '@kwenta/sdk/utils'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import type { ConditionOrderTableItemCross } from 'types/futures'

import { ERROR_MESSAGES } from 'components/ErrorNotifier'
import {
	DEFAULT_DELAYED_CANCEL_BUFFER,
	MARGIN_ENGINE_ENABLED,
	V3_CONDITIONAL_ORDERS_ENABLED,
} from 'constants/defaults'
import { REQUIRED_MARGIN_RATIO, SNX_V3_DEPOSIT_OPTIONS } from 'constants/perpsV3'
import { selectBalances, selectTokenAllowances } from 'state/balances/selectors'
import { KEEPER_USD_GAS_FEE, ZERO_STATE_MARGIN_INFO } from 'state/constants'
import { selectPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { selectWallet } from 'state/wallet/selectors'
import {
	calculateKeeperFeesForOrders,
	calculateV3LiqPrice,
	formatFuturesPositions,
	previewStatusToI18nMsg,
	providerIsCrossMargin,
	unserializeTrades,
	unserializeV3Liquidations,
} from 'utils/futures'

import { unserializeCrossMarginTradePreview } from '../../../utils/futures'
import {
	selectAllMarkPriceInfos,
	selectLeverageSide,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectMarketsByProvider,
	selectPerpsProvider,
	selectSelectedPortfolioTimeframe,
	selectSnxV3Network,
	selectSnxV3Provider,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectUserInfoShowAllMarkets,
	selectV3Markets,
} from '../common/selectors'
import { CrossMarginRiskStatus, type MarkPrices } from '../common/types'
import type { SnxV3AccountData } from './types'

export const selectV3MarketAsset = createSelector(
	selectSnxV3Provider,
	(state: RootState) => state.futures.selectedMarketAsset,
	(provider, asset) => {
		return asset[provider]
	}
)

export const selectMargineEngineEnabled = (_: RootState) => MARGIN_ENGINE_ENABLED

export const selectCondOrdersEnabled = createSelector(
	selectPerpsProvider,
	selectMargineEngineEnabled,
	(futuresType, margineEngineEnabled) => {
		return (
			futuresType === PerpsProvider.SNX_V2_OP ||
			futuresType === PerpsProvider.PERENNIAL_V2_ARB ||
			(V3_CONDITIONAL_ORDERS_ENABLED && margineEngineEnabled)
		)
	}
)

export const selectMarkPricesV3ByProvider = createSelector(
	selectMarketsByProvider,
	selectPrices,
	(v3Markets, prices) => {
		const markPrices: MarkPrices = {}
		const format = (markets: PerpsMarketV3[]) => {
			return markets.reduce((acc, market) => {
				const price = prices[market.asset]?.offChain ?? wei(0)
				acc[market.asset] = wei(price).mul(
					wei(market.marketSkew).div(market.settings.skewScale).add(1)
				)
				return acc
			}, markPrices)
		}
		return {
			[PerpsProvider.SNX_V3_BASE]: format(v3Markets.snx_v3_base),
			[PerpsProvider.SNX_V3_ARB]: format(v3Markets.snx_v3_arb),
		}
	}
)

export const selectMarkPricesV3 = createSelector(
	selectSnxV3Provider,
	selectMarkPricesV3ByProvider,
	(provider, prices) => {
		return prices[provider]
	}
)

export const selectV3SelectedMarketInfo = createSelector(
	selectV3Markets,
	selectV3MarketAsset,
	(markets, asset) => {
		return markets.find((market) => market.asset === asset)
	}
)

export const selectV3SelectedMarketId = createSelector(
	selectV3SelectedMarketInfo,
	(marketInfo) => marketInfo?.marketId
)

export const selectSignerSupportsSnxV3Arb = (state: RootState) =>
	state.wallet.walletNetworkId === SupportedNetworkIds.ARB_SEPOLIA ||
	state.wallet.walletNetworkId === SupportedNetworkIds.ARB_MAINNET

export const selectSnxV3Account = createSelector(
	selectPerpsProvider,
	selectWallet,
	selectSnxV3Network,
	(state: RootState) => state.futures.accounts,
	(provider, wallet, network, accounts) => {
		if (!providerIsCrossMargin(provider)) return
		const accountData = wallet ? accounts[provider]?.[wallet] : undefined

		if (accountData?.network === network) {
			return BigInt(accountData.account)
		}
		return
	}
)

export const selectSnxV3AccountContext = createSelector(
	selectPerpsProvider,
	selectWallet,
	selectSnxV3Network,
	selectSnxV3Account,
	(provider, wallet, network, accountId) => {
		return {
			wallet,
			network,
			accountId: accountId?.toString(),
			provider: (provider === PerpsProvider.SNX_V3_BASE
				? PerpsProvider.SNX_V3_BASE
				: PerpsProvider.SNX_V3_ARB) as PerpsProvider.SNX_V3_ARB | PerpsProvider.SNX_V3_BASE,
			isIsolatedMargin: false,
		}
	}
)

export const selectCrossMarginAccountsData = createSelector(
	selectWallet,
	(state: RootState) => state.futures.accounts,
	(wallet, accounts) => {
		return {
			[PerpsProvider.SNX_V3_BASE]: wallet
				? accounts[PerpsProvider.SNX_V3_BASE]?.[wallet]
				: undefined,
			[PerpsProvider.SNX_V3_ARB]: wallet ? accounts[PerpsProvider.SNX_V3_ARB]?.[wallet] : undefined,
		}
	}
)

export const selectSelectedCrossMarginAccountData = createSelector(
	selectWallet,
	selectPerpsProvider,
	selectSnxV3Network,
	(state: RootState) => state.futures.accounts,
	(wallet, provider, network, accounts) => {
		if (provider !== PerpsProvider.SNX_V3_BASE && provider !== PerpsProvider.SNX_V3_ARB) return
		const accountData = wallet ? accounts[provider]?.[wallet] : undefined
		return accountData?.network === network ? accountData : undefined
	}
)

export const selectMarginEnginePermitted = createSelector(
	selectSelectedCrossMarginAccountData,
	selectMargineEngineEnabled,
	(account, engineEnabled) => {
		return engineEnabled && !!account?.marginEnginePermitted
	}
)

export const selectProviderSupportsZap = createSelector(selectPerpsProvider, (provider) => {
	return provider === PerpsProvider.SNX_V3_BASE || provider === PerpsProvider.SNX_V3_ARB
})

export const selectCrossMarginMarginInfo = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		const {
			availableMargin,
			withdrawableMargin,
			requiredInitialMargin,
			requiredMaintenanceMargin,
			maxLiquidationReward,
			debt,
		} = account ? account.marginInfo : ZERO_STATE_MARGIN_INFO

		return {
			availableMargin: wei(availableMargin).gt(0) ? wei(availableMargin) : wei(0),
			withdrawableMargin: wei(withdrawableMargin),
			requiredInitialMargin: wei(requiredInitialMargin),
			maxLiquidationReward: wei(maxLiquidationReward),
			debt: wei(debt ?? 0),
			requiredMaintenanceMargin: wei(requiredMaintenanceMargin).gt(1)
				? wei(requiredMaintenanceMargin)
				: wei(0),
		}
	}
)

export const selectCrossMarginPendingOrders = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		const orders = account?.conditionalOrders ?? []
		return orders.filter((o) => o.status === 'Pending')
	}
)

export const selectReservedMarginForOrders = createSelector(
	selectCrossMarginPendingOrders,
	selectV3Markets,
	(pendingOrders, markets) => {
		const ordersRequiringMargin = pendingOrders.filter((o) => !o.orderDetails.isReduceOnly)

		const totalReserved = ordersRequiringMargin.reduce<Wei>((acc, order) => {
			const market = markets.find((m) => m.marketId === order.orderDetails.marketId)
			if (!market) return acc

			const orderPrice =
				order.decodedConditions.isPriceAbove ?? order.decodedConditions.isPriceBelow
			if (!orderPrice) return acc

			const notionalSize = wei(order.orderDetails.sizeDelta).abs().mul(wei(orderPrice))

			return acc.add(notionalSize.mul(REQUIRED_MARGIN_RATIO))
		}, wei(0))
		return totalReserved
	}
)

export const selectCrossMarginAvailableMargin = createSelector(
	selectCrossMarginMarginInfo,
	(marginInfo) => {
		return marginInfo.availableMargin
	}
)

export const selectMaintenanceMarginRatio = createSelector(
	selectCrossMarginMarginInfo,
	({ availableMargin, requiredMaintenanceMargin }) => {
		// Using > 1 as requiredMaintenanceMargin is always at least 1

		const ratio = requiredMaintenanceMargin.gt(1)
			? availableMargin.gt(0)
				? requiredMaintenanceMargin.div(availableMargin)
				: wei(1)
			: wei(0)
		const riskStatus = ratio.gt(0.75)
			? CrossMarginRiskStatus.High
			: ratio.gt(0.5)
				? CrossMarginRiskStatus.Moderate
				: CrossMarginRiskStatus.Low
		return { ratio, riskStatus }
	}
)

export const selectV3Spenders = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.futures.providerData,
	(provider, providerData) => providerData.spenders[provider]
)

type DepositAllowances = { marketProxy: Wei; marginEngine: Wei; spotProxy: Wei }

export const selectDepositAllowances = createSelector(
	selectV3Spenders,
	selectTokenAllowances,
	(spenders, tokenAllowances) => {
		if (!spenders) return {}

		const tokenAllowancesFormatted = Object.keys(tokenAllowances).reduce<
			Partial<Record<string, DepositAllowances>>
		>((acc, key) => {
			const asset = key as TokenTickers
			acc[asset] = {
				marketProxy: spenders.marketProxy
					? tokenAllowances[asset]?.[spenders.marketProxy] ?? wei(0)
					: wei(0),
				marginEngine: spenders.marginEngine
					? tokenAllowances[asset]?.[spenders.marginEngine] ?? wei(0)
					: wei(0),
				spotProxy: spenders.spotProxy
					? tokenAllowances[asset]?.[spenders.spotProxy] ?? wei(0)
					: wei(0),
			}
			return acc
		}, {})

		return tokenAllowancesFormatted
	}
)

type CrossMarginTrade = FuturesTrade & {
	market: PerpsMarketV3
	txnHash?: string
}

type CrossMarginLiquidation = PerpsV3Liquidation & {
	market: PerpsMarketV3
	txnHash?: string
}

type StableV3Asset = SynthAssetKeysV3.USDx | SynthAssetKeysV3.sUSDe | SynthAssetKeysV3.sUSDC

export const selectV3SynthPrices = createSelector(
	selectV3Markets,
	selectPrices,
	(markets, prices) => {
		const synthKeys = Object.values(SynthAssetKeysV3) as string[]
		const stablePrices = Object.keys(COLLATERAL_PYTH_IDS).reduce<
			Partial<Record<SynthAssetKeysV3.USDx | SynthAssetKeysV3.sUSDe | SynthAssetKeysV3.sUSDC, Wei>>
		>(
			(acc, key) => {
				const asset = key as TokenTickers
				const price = prices[asset]?.offChain ?? wei(0)
				acc[`s${asset}` as StableV3Asset] = price
				return acc
			},
			{ [SynthAssetKeysV3.USDx]: wei(1) }
		)
		const marketPrices = markets.reduce<Partial<Record<SynthAssetKeysV3, Wei>>>((acc, market) => {
			const price = prices[market.asset]?.offChain ?? wei(0)
			if (synthKeys.includes(market.asset)) {
				acc[market.asset as string as SynthAssetKeysV3] = price
			} else if (market.asset === FuturesMarketAsset.SOL) {
				acc[SynthAssetKeysV3.swSOL] = price
			}
			return acc
		}, {})
		return {
			...stablePrices,
			...marketPrices,
		}
	}
)

export const selectCrossMarginConditionalOrders = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		return account?.conditionalOrders ?? []
	}
)

export const selectAllCrossMarginOrderTableItems = createSelector(
	selectV3Markets,
	selectAllMarkPriceInfos,
	selectCrossMarginAccountsData,
	(markets, priceInfos, accounts) => {
		const formatOrders = (accountData: SnxV3AccountData | undefined) => {
			return accountData
				? accountData.conditionalOrders
						.filter((o) => o.status === 'Pending')
						.reduce<ConditionOrderTableItemCross[]>((acc, order) => {
							const targetPrice =
								order.decodedConditions.isPriceAbove ?? order.decodedConditions.isPriceBelow
							const market = markets.find((m) => m.marketId === order.orderDetails.marketId)
							if (!market || !targetPrice) return acc

							const priceInfo = priceInfos[PerpsProvider.SNX_V3_BASE][market.asset]
							if (!priceInfo) return acc

							const position = accountData?.positions?.find((p) => p.marketId === market?.marketId)
							const sizeDelta = wei(order.orderDetails.sizeDelta)
							const notionalSize = wei(order.orderDetails.sizeDelta).abs().mul(wei(targetPrice))
							const reservedMargin = order.orderDetails.isReduceOnly
								? wei(0)
								: notionalSize.mul(REQUIRED_MARGIN_RATIO)

							const orderType = orderTypeFromCondition(position?.details.side, order)
							const formattedOrder: ConditionOrderTableItemCross = {
								id: Number(order.id),
								provider: PerpsProvider.SNX_V3_BASE,
								createdAt: new Date(order.createdAt),
								status: order.status,
								nonce: Number(order.nonce),
								updatedAt: order.updatedAt ? new Date(order.updatedAt) : undefined,
								account: order.orderDetails.accountId,
								marketName: market.marketName,
								asset: market.asset,
								size: sizeDelta,
								maxExecutorFee: wei(order.maxExecutorFee),
								targetPrice: wei(targetPrice),
								desiredFillPrice: wei(order.orderDetails.acceptablePrice),
								sizeTxt: sizeDelta.abs().eq(SL_TP_MAX_SIZE_CROSS_MARGIN)
									? 'Close'
									: formatCurrency(market.asset, sizeDelta, {
											currencyKey: getDisplayAsset(market.asset) ?? '',
											suggestDecimals: true,
										}),
								marginDelta: reservedMargin,
								orderType: orderType,
								orderTypeDisplay: formatOrderDisplayType(orderType),
								reduceOnly: order.orderDetails.isReduceOnly,
								side: sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
								isSlTp: sizeDelta.abs().eq(SL_TP_MAX_SIZE_CROSS_MARGIN),
								currentPrice: priceInfo,
								tradeDirection: sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
							}
							acc.push(formattedOrder)
							return acc
						}, [])
				: []
		}

		return {
			[PerpsProvider.SNX_V3_BASE]: formatOrders(accounts[PerpsProvider.SNX_V3_BASE]),
			[PerpsProvider.SNX_V3_ARB]: formatOrders(accounts[PerpsProvider.SNX_V3_ARB]),
		}
	}
)

export const selectCrossMarginOrderTableItemsForProvider = createSelector(
	selectAllCrossMarginOrderTableItems,
	selectPerpsProvider,
	(orders, provider) => {
		if (provider !== PerpsProvider.SNX_V3_BASE && provider !== PerpsProvider.SNX_V3_ARB) return []
		return providerIsCrossMargin(provider) ? orders[provider] : []
	}
)

export const selectAllSnxV3SLTPOrders = createSelector(
	selectCrossMarginOrderTableItemsForProvider,
	(orders) => {
		return orders.filter(
			(o) =>
				o.reduceOnly && (o.orderTypeDisplay === 'Stop Loss' || o.orderTypeDisplay === 'Take Profit')
		)
	}
)

export const selectRequiredUsdForPendingOrders = createSelector(
	selectCrossMarginOrderTableItemsForProvider,
	(orders) => {
		return calculateKeeperFeesForOrders(
			orders.map((o) => ({ ...o, maxExecutorFee: wei(KEEPER_USD_GAS_FEE) }))
		)
	}
)

export const selectKeeperUSDBalance = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		return wei(account?.usdcBalance ?? 0)
	}
)

export const selectRequiredKeeperUsdDeposit = createSelector(
	selectRequiredUsdForPendingOrders,
	selectKeeperUSDBalance,
	(requiredUsd, usdBal) => {
		return wei(requiredUsd).gt(usdBal) ? wei(requiredUsd).sub(usdBal) : ZERO_WEI
	}
)

export const selectCrossMarginPositions = createSelector(
	selectSelectedCrossMarginAccountData,
	selectV3Markets,
	selectAllSnxV3SLTPOrders,
	selectMarkPricesV3,
	(account, markets, sltpOrders, prices) => {
		const positions = account?.positionHistory ?? []
		return formatFuturesPositions(positions, markets, sltpOrders, prices)
	}
)

export const selectCrossMarginActivePositions = createSelector(
	selectSelectedCrossMarginAccountData,
	selectV3Markets,
	selectAllSnxV3SLTPOrders,
	selectMarkPricesV3,
	(account, markets, sltpOrders, prices) => {
		const positions = account?.positions ?? []
		return formatFuturesPositions(positions, markets, sltpOrders, prices)
	}
)

export const selectSelectedSnxV3Position = createSelector(
	selectCrossMarginActivePositions,
	selectV3SelectedMarketInfo,
	(positions, market) => {
		const position = positions.find((p) => p.market.asset === market?.asset)
		return position
	}
)

export const selectAsyncCrossMarginOrder = createSelector(
	selectV3Markets,
	selectSelectedCrossMarginAccountData,
	(markets, account) => {
		const order = account?.pendingAsyncOrder
		if (!order) return

		const market = markets.find((m) => m.marketId === order.marketId)

		const strategy = market?.settlementStrategies.find(
			(s) => s.strategyId === order.settlementStrategyId
		)
		if (account && market && strategy) {
			const startTime = Number(order.settlementTime)
			const expirationTime = startTime + Number(strategy.settlementWindowDuration)
			const timePastExecution = Math.floor(Date.now() / 1000 - startTime)
			const executable = timePastExecution <= expirationTime
			const isStale =
				timePastExecution >
				DEFAULT_DELAYED_CANCEL_BUFFER + strategy.settlementWindowDuration.toNumber()

			const orderDetails = {
				market,
				account: Number(account.account),
				size: wei(order.sizeDelta),
				executableStartTime: startTime,
				expirationTime: expirationTime,
				marginDelta: wei(0),
				desiredFillPrice: wei(order.acceptablePrice),
				settlementWindowDuration: strategy.settlementWindowDuration.toNumber(),
				side: order.side,
				isStale: isStale,
				isExecutable: executable,
				settlementFee: strategy.settlementReward,
			}
			return orderDetails
		}
		return
	}
)

export const selectShowCrossMarginOnboard = (state: RootState) =>
	state.app.showModal?.type === 'futures_cross_margin_onboard'

export const selectWithdrawableCrossMargin = createSelector(
	selectCrossMarginActivePositions,
	selectCrossMarginMarginInfo,
	(positions, marginInfo) => {
		if (positions.length === 0) return marginInfo.availableMargin
		return marginInfo.withdrawableMargin.gt(0) ? marginInfo.withdrawableMargin : wei(0)
	}
)

export const selectSnxV3Preview = createSelector(
	selectSnxV3Provider,
	(state: RootState) => state.futures.tradePreviews,
	(provider, previews) => previews[provider]
)

export const selectCrossMarginTradePreview = createSelector(
	selectTradePanelInputs,
	selectTradeOrderType,
	selectCrossMarginMarginInfo,
	selectV3SelectedMarketInfo,
	selectSnxV3Preview,
	({ nativeSizeDelta, orderPrice }, orderType, marginInfo, market, preview) => {
		const priceImpact = getDefaultPriceImpact(orderType)

		const desiredFillPrice = calculateDesiredFillPrice(
			nativeSizeDelta,
			wei((orderType === OrderTypeEnum.MARKET ? preview?.fillPrice : orderPrice.price) ?? 0),
			priceImpact
		)

		const unserializedPreview = preview ? unserializeCrossMarginTradePreview(preview) : undefined
		if (!unserializedPreview || !market) return

		const liqPrice = unserializedPreview.fillPrice
			? calculateV3LiqPrice(marginInfo, unserializedPreview, market)
			: wei(0)

		return unserializedPreview
			? {
					...unserializedPreview,
					desiredFillPrice,
					orderType,
					liqPrice,
				}
			: undefined
	}
)

export const selectCrossMarginTradeableMargin = createSelector(
	selectCrossMarginAvailableMargin,
	selectReservedMarginForOrders,
	selectCrossMarginMarginInfo,
	(availableMargin, reserved, { requiredInitialMargin }) => {
		const tradeble = availableMargin.sub(reserved).sub(requiredInitialMargin)
		return tradeble.gte(0) ? tradeble : wei(0)
	}
)

// TODO: Improve max usd value for perps v3 (waiting for price param on requiredMarginForOrder)
export const selectMaxUsdSizeInputCrossMargin = createSelector(
	selectCrossMarginTradeableMargin,
	(tradeableMargin) => {
		return tradeableMargin.div(REQUIRED_MARGIN_RATIO)
	}
)

export const selectSnxV3MaxLeverage = createSelector(
	selectMaxUsdSizeInputCrossMargin,
	selectCrossMarginTradeableMargin,
	(maxUsd, tradeableMargin) => {
		if (tradeableMargin.eq(0)) return wei(0)
		const leverage = wei(floorNumber(maxUsd.div(tradeableMargin), 1))
		return leverage.gt(0) ? leverage : wei(0)
	}
)
export const selectPendingAsyncOrdersCount = createSelector(
	selectAsyncCrossMarginOrder,
	(order) => {
		return order && !order.isStale ? 1 : 0
	}
)

export const selectActiveCrossMarginPositionsCount = createSelector(
	selectCrossMarginAccountsData,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(accounts, userInfoShowAllMarkets, asset) => {
		const base = accounts[PerpsProvider.SNX_V3_BASE]?.positions ?? []
		const arb = accounts[PerpsProvider.SNX_V3_ARB]?.positions ?? []

		const filter = (p: PerpsV3Position<string>) =>
			(userInfoShowAllMarkets ?? p.asset === asset) && p.details.status === 'open'
		return {
			[PerpsProvider.SNX_V3_BASE]: base.filter(filter).length,
			[PerpsProvider.SNX_V3_ARB]: arb.filter(filter).length,
		}
	}
)

export const selectV3SkewAdjustedPrice = createSelector(
	selectMarketIndexPrice,
	selectV3SelectedMarketInfo,
	(price, marketInfo) => {
		if (!marketInfo?.marketSkew || !marketInfo?.settings.skewScale) return price
		return price
			? wei(price).mul(wei(marketInfo.marketSkew).div(marketInfo.settings.skewScale).add(1))
			: ZERO_WEI
	}
)
export const selectCollateralBalances = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		return account?.collateralBalances ?? {}
	}
)

export const selectCrossMarginAccountTrades = createSelector(
	selectSelectedCrossMarginAccountData,
	selectV3Markets,
	(account, markets) => {
		const trades = unserializeTrades(account?.trades ?? [])
		return trades.reduce<CrossMarginTrade[]>((acc, t) => {
			const market = markets.find((m) => m.marketId === t.marketId)
			if (market) {
				acc.push({
					...t,
					market: market,
				})
			}
			return acc
		}, [])
	}
)

export const selectCrossMarginAccountLiquidations = createSelector(
	selectSelectedCrossMarginAccountData,
	selectV3Markets,
	(account, markets) => {
		const liquidations = unserializeV3Liquidations(account?.liquidations ?? [])
		return liquidations.reduce<CrossMarginLiquidation[]>((acc, t) => {
			const market = markets.find((m) => m.marketId === t.marketId)
			if (market) {
				acc.push({
					...t,
					market: market,
				})
			}
			return acc
		}, [])
	}
)

export const selectSnxV3GlobalTradesForMarket = createSelector(
	selectSnxV3Provider,
	selectMarketAsset,
	(state: RootState) => state.futures.providerData.globalTradeHistory,
	(provider, marketAsset, tradesHistory) => {
		if (!marketAsset) return []
		return unserializeTrades(tradesHistory[provider]?.[marketAsset] ?? [])
	}
)

export const selectGlobalLiquidationsForMarket = createSelector(
	selectSnxV3Provider,
	selectV3SelectedMarketId,
	(state: RootState) => state.futures.providerData.globalLiquidationHistory ?? {},
	(provider, marketId, liquidationsHistory) => {
		if (!marketId) return []
		return unserializeV3Liquidations(liquidationsHistory[provider]?.[marketId] ?? [])
	}
)

export const selectIsV3MarketCapReached = createSelector(
	selectLeverageSide,
	selectV3SelectedMarketInfo,
	selectMarketIndexPrice,
	(leverageSide, marketInfo, marketAssetRate) => {
		const side = leverageSide === PositionSide.LONG ? 'long' : 'short'
		const maxMarketValueUSD = marketInfo?.marketLimitUsd[side] ?? wei(0)
		const marketSize = marketInfo?.openInterest[leverageSide] ?? wei(0)

		return leverageSide === PositionSide.LONG
			? marketSize.abs().mul(marketAssetRate).gte(maxMarketValueUSD)
			: marketSize.abs().mul(marketAssetRate).gte(maxMarketValueUSD)
	}
)

export const selectAvailableMarginQueryStatuses = (state: RootState) =>
	state.futures.queryStatuses.get_balance_info?.status

export const selectAssetsV3 = createSelector(
	selectBalances,
	selectCollateralBalances,
	selectV3SynthPrices,
	selectPerpsProvider,
	(walletBalances, marginBalances, prices, provider) => {
		const unFilteredAssets = V3_SYNTH_KEYS.map((asset) => {
			const ticker =
				provider === PerpsProvider.SNX_V3_BASE && asset === SynthAssetKeysV3.USDx
					? 'USDC'
					: assetToTokenTicker(asset)

			const price = asset === 'sUSDC' || asset === 'USDx' ? wei(1) : prices[asset] ?? ZERO_WEI

			return {
				asset:
					provider === PerpsProvider.SNX_V3_BASE && asset === SynthAssetKeysV3.USDx
						? SynthAssetKeysV3.sUSDC
						: asset,
				token: ticker,
				walletBalance: {
					size: wei(walletBalances[ticker]?.balance || ZERO_WEI),
					value: price.mul(walletBalances[ticker]?.balance || ZERO_WEI),
				},
				marginBalance: {
					size: wei(marginBalances[asset] ?? 0),
					value: price.mul(wei(marginBalances[asset] ?? 0)),
				},
			}
		})

		return unFilteredAssets.filter(
			(asset) => asset.marginBalance.value.gt(0) || asset.walletBalance.value.gt(0)
		)
	}
)
export const selectV3MarginChartData = createSelector(
	selectSelectedCrossMarginAccountData,
	selectCrossMarginAvailableMargin,
	selectSelectedPortfolioTimeframe,
	(accountData, currentMargin, period) => {
		const snapshots = accountData?.marginSnapshots[period]
		if (!snapshots) return []
		const data = snapshots.map((s) => ({
			...s,
			total: wei(s.margin).toNumber(),
			timestamp: s.timestamp * 1000,
		}))
		return [
			...data,
			{
				timestamp: Date.now(),
				total: currentMargin.toNumber(),
			},
		]
	}
)

export const selectCMPlaceOrderDisabledReason = createSelector(
	selectCrossMarginTradePreview,
	selectV3SelectedMarketInfo,
	selectIsV3MarketCapReached,
	selectSelectedSnxV3Position,
	selectTradePanelInputs,
	selectMaxUsdSizeInputCrossMargin,
	selectPendingAsyncOrdersCount,
	selectLeverageSide,
	(
		previewTrade,
		marketInfo,
		isMarketCapReached,
		position,
		{ susdSize },
		maxUsdInputAmount,
		pendingOrderCount,
		leverageSide
	) => {
		const MessageType = {
			error: 'error',
			warn: 'warn',
		} as const
		if (previewTrade?.statusMessage && previewTrade.statusMessage !== 'Success') {
			return { message: previewTrade?.statusMessage, show: MessageType.error }
		}

		if (previewTrade && previewTrade.status !== PotentialTradeStatus.OK) {
			const i18nMessage = previewStatusToI18nMsg(previewTrade.status)
			return { message: i18nMessage, show: MessageType.error }
		}

		const increasingPosition = !position?.details.side || position?.details.side === leverageSide

		// TODO: Validate liquidation from preview
		const canLiquidate = false
		if (canLiquidate) {
			return {
				show: MessageType.warn,
				message: 'Position can be liquidated',
			}
		}

		if (marketInfo?.isSuspended)
			return {
				show: MessageType.warn,
				message: 'Market suspended',
			}
		if (isMarketCapReached && increasingPosition)
			return {
				show: MessageType.warn,
				message: 'Open interest limit exceeded',
			}

		if (susdSize.gt(maxUsdInputAmount))
			return {
				show: MessageType.warn,
				message: 'Max trade size exceeded',
			}

		if (isZero(susdSize)) {
			return { message: 'Trade size required' }
		}
		if (pendingOrderCount) {
			return {
				show: MessageType.warn,
				message: ERROR_MESSAGES.ORDER_PENDING,
			}
		}

		return null
	}
)

export const selectCMPlaceOrderTranslationKey = createSelector(
	selectSelectedSnxV3Position,
	(position) => {
		return position?.details
			? 'futures.market.trade.button.modify-position'
			: 'futures.market.trade.button.open-position'
	}
)

export const selectV3MaxDepositAmounts = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.futures.providerData,
	(provider, providerData) => {
		return providerData.maxDepositAmounts[provider]
	}
)

export const selectV3SupportedCollaterals = createSelector(
	(state: RootState) => state.futures.providerData.supportedCollaterals[PerpsProvider.SNX_V3_ARB],
	(supportedCollaterals) => {
		return {
			[PerpsProvider.SNX_V3_ARB]: supportedCollaterals,
			[PerpsProvider.SNX_V3_BASE]: [SynthAssetKeysV3.USDx],
		}
	}
)

export const selectV3DepositOptions = createSelector(
	selectV3SupportedCollaterals,
	selectSnxV3Provider,
	(supportedCollaterals, provider) => {
		const options = SNX_V3_DEPOSIT_OPTIONS[provider]
		const collaterals = supportedCollaterals[provider]
		return (
			options?.filter((token) => {
				const synth = DepositTokenToSynth[provider][token]
				return synth && (collaterals?.includes(synth) || SwappableDepositOptions[provider]?.[token])
			}) ?? []
		)
	}
)

export const selectDebtPaymentQuote = createSelector(
	selectCrossMarginMarginInfo,
	(state: RootState) => state.futures.snxV3.debtPaymentQuote,
	({ debt }, quote) => {
		if (!debt || !quote) return

		return {
			token: quote.token,
			debtPaymentUsdc: wei(quote.amountOut),
			debtPaymentCollateral: wei(quote.amountIn),
			indexPrice: wei(quote.indexPrice),
			priceImpact: wei(quote.priceImpact).gt(0) ? wei(quote.priceImpact) : ZERO_WEI,
			quotedPrice: wei(quote.quotedPrice),
		}
	}
)
