import { decodeErrorResult, encodeFunctionData, } from 'viem';
import { PYTH_ORACLE_ADDRESSES, PYTH_SERVER_PRIMARY, SNX_V3_PERPS_ADDRESSES, } from '../../constants';
import IERC7412 from '../../contracts/abis/IERC7412.js';
import MarginEngineAbi from '../../contracts/abis/MarginEngine.js';
import MarginEngineMultiCAbi from '../../contracts/abis/MarginEngineMultiCollateral';
import PerpsV3MarketProxy from '../../contracts/abis/PerpsV3MarketProxy.js';
import SpotV3MarketProxy from '../../contracts/abis/SpotV3MarketProxy.js';
import { decodeTransactionError } from '../../utils';
import { PythAdapter, encodePriceUpdateData } from './PythAdapter';
const ADAPTER = new PythAdapter(PYTH_SERVER_PRIMARY);
const STALENESS_TOLERANCE = {
    ZERO: 0n,
    STRICT: 60n,
};
const CACHE_TIME = 5000;
export class EIP7412 {
    constructor() {
        this.cachedPriceUpdates = new Map();
    }
    async preparePredefinedUpdates(predefinedOracles, chainId, tolerance, useMarginEngine) {
        let updateData;
        const cached = this.cachedPriceUpdates.get(predefinedOracles.join(','));
        if (cached && Date.now() - cached.timestamp < CACHE_TIME) {
            // Cache price updates for 5 seconds
            updateData = cached.data;
        }
        else {
            const updateDataResponse = await ADAPTER.connection.getLatestPriceUpdates(predefinedOracles);
            updateData = updateDataResponse.binary.data.map((x) => `0x${x}`);
            this.cachedPriceUpdates.set(predefinedOracles.join(','), {
                data: updateData,
                ids: predefinedOracles,
                timestamp: Date.now(),
            });
        }
        const priceUpdates = encodePriceUpdateData(updateData, predefinedOracles, STALENESS_TOLERANCE[tolerance], 1);
        const priceUpdateCall = preparePriceUpdateCall({
            chainId,
            data: priceUpdates,
            feedsUpdated: predefinedOracles.length,
            useMarginEngine,
        });
        return priceUpdateCall;
    }
    async enableERC7412(client, transactions, multicallFunc, { predefinedOracles, chainId, useMarginEngine, }) {
        let multicallCalls = [...transactions];
        let multicallTxn;
        let requestedIds = [];
        // First prepend any price updates of oracles we need
        // latest price for if it's a readonly request
        if (predefinedOracles?.ids.length && predefinedOracles.prepend) {
            const priceUpdateCall = await this.preparePredefinedUpdates(predefinedOracles.ids, chainId, predefinedOracles.tolerance ?? 'ZERO', useMarginEngine);
            multicallCalls = [priceUpdateCall, ...multicallCalls];
        }
        const initialCallCount = multicallCalls.length;
        while (true) {
            try {
                multicallTxn = multicallFunc([...multicallCalls], chainId);
                await client.call(multicallTxn);
                return multicallTxn;
            }
            catch (error) {
                const decodedError = decodeTransactionError(error, [
                    IERC7412,
                    PerpsV3MarketProxy,
                    SpotV3MarketProxy,
                    MarginEngineAbi,
                    MarginEngineMultiCAbi,
                ]);
                if (!decodedError)
                    throw error;
                const errors = decodedError.errorName === 'Errors'
                    ? decodedError.args[0].map((e) => decodeErrorResult({ data: e, abi: IERC7412 }))
                    : [decodedError];
                const oracleUpdateQueries = errors
                    .filter((e) => e.errorName === 'OracleDataRequired')
                    .map((e) => ({
                    data: e.args[1],
                    oracleAddress: e.args[0],
                    fee: e.args[2],
                }));
                const otherErrors = errors.filter((e) => e.errorName !== 'OracleDataRequired');
                if (oracleUpdateQueries.length) {
                    // For write calls we wait for one price request and then request all markets.
                    // This is due to snx sometimes requiring a large number of prices to calculate
                    // OI across markets, which when left for EIP7412 to resolve can cause the app to stall.
                    const extraPriceIds = predefinedOracles && !predefinedOracles.prepend ? predefinedOracles.ids : [];
                    const allIds = [
                        ...new Set([...requestedIds, ...extraPriceIds, ...(predefinedOracles?.ids ?? [])]),
                    ];
                    const priceUpdates = await ADAPTER.fetchOffchainData(oracleUpdateQueries.map((o) => o.data), allIds);
                    priceUpdates.forEach((update) => {
                        const tx = preparePriceUpdateCall({
                            chainId,
                            data: update.data,
                            feedsUpdated: update.ids.length,
                            address: oracleUpdateQueries[0].oracleAddress,
                            useMarginEngine,
                        });
                        requestedIds = [...new Set([...requestedIds, ...update.ids])];
                        // Always replace the price update call with the new one
                        multicallCalls.length > initialCallCount ||
                            (predefinedOracles?.ids.length && predefinedOracles.prepend)
                            ? multicallCalls.splice(0, 1, tx)
                            : multicallCalls.splice(0, 0, tx);
                    });
                }
                for (const err of otherErrors) {
                    if (!err)
                        continue;
                    if (err.errorName === 'FeeRequired') {
                        const requiredFee = err.args?.[0];
                        // @ts-ignore
                        multicallCalls[multicallCalls.length - 2].value = requiredFee;
                    }
                    else {
                        throw new Error(
                        // @ts-ignore
                        `Failed to simulate tx: ${err.errorName} - ${err.args
                            ?.map((x) => x.toString())
                            .join(',')}`);
                    }
                }
            }
        }
    }
}
const preparePriceUpdateCall = ({ chainId, data, feedsUpdated, address, useMarginEngine, }) => {
    const oracleAddress = address || PYTH_ORACLE_ADDRESSES[chainId];
    if (!oracleAddress)
        throw new Error('Oracle address not found');
    return useMarginEngine
        ? {
            to: SNX_V3_PERPS_ADDRESSES.MarginEngine[chainId],
            value: BigInt(feedsUpdated),
            data: encodeFunctionData({
                abi: MarginEngineAbi,
                functionName: 'fulfillOracleQuery',
                args: [oracleAddress, data],
            }),
        }
        : {
            to: oracleAddress,
            value: BigInt(feedsUpdated),
            data: encodeFunctionData({
                abi: IERC7412,
                functionName: 'fulfillOracleQuery',
                args: [data],
            }),
        };
};
