// TODO: Make this dynamic
import { wei } from '@kwenta/wei';
import { COMMON_ADDRESSES } from '../contracts/constants';
import { FuturesMarketKey, PerpsProvider, SnxV3NetworkIds, SupportedNetworkIds, } from '../types';
import { MarketAssetByKey, MarketKeyByAsset, chainToV3Provider, inverseObj } from '../utils';
import { COLLATERAL_PYTH_IDS, PERPS_PYTH_IDS, SynthAssetKeysV3, V3_WRAPPED_TOKEN_MARKETS, } from './futures';
export const V3_PERPS_MARKET_IDS = {
    [PerpsProvider.SNX_V3_BASE]: {
        [FuturesMarketKey.sETHPERP]: '100',
        [FuturesMarketKey.sBTCPERP]: '200',
        [FuturesMarketKey.sSNXPERP]: '300',
        [FuturesMarketKey.sSOLPERP]: '400',
        [FuturesMarketKey.sWIFPERP]: '500',
        [FuturesMarketKey.sWPERP]: '600',
        [FuturesMarketKey.sENAPERP]: '700',
        [FuturesMarketKey.sDOGEPERP]: '800',
        [FuturesMarketKey.sAVAXPERP]: '900',
        [FuturesMarketKey.sOPPERP]: '1000',
        [FuturesMarketKey.sORDIPERP]: '1100',
        [FuturesMarketKey.sPEPEPERP]: '1200',
        [FuturesMarketKey.sRUNEPERP]: '1300',
        [FuturesMarketKey.sBONKPERP]: '1400',
        [FuturesMarketKey.sFTMPERP]: '1500',
        [FuturesMarketKey.sARBPERP]: '1600',
        [FuturesMarketKey.sPOLPERP]: '1700',
        [FuturesMarketKey.sBNBPERP]: '1800',
        [FuturesMarketKey.sLINKPERP]: '1900',
        [FuturesMarketKey.sPENDLEPERP]: '2000',
        [FuturesMarketKey.sINJPERP]: '2100',
        [FuturesMarketKey.sGMXPERP]: '2200',
        [FuturesMarketKey.sTIAPERP]: '2300',
        [FuturesMarketKey.sSUIPERP]: '2400',
        [FuturesMarketKey.sTONPERP]: '2500',
        [FuturesMarketKey.sARKMPERP]: '2600',
        [FuturesMarketKey.sGALAPERP]: '2700',
        [FuturesMarketKey.sTAOPERP]: '2800',
        [FuturesMarketKey.sBOMEPERP]: '2900',
        [FuturesMarketKey.sETHFIPERP]: '3000',
        [FuturesMarketKey.sSTXPERP]: '3100',
        [FuturesMarketKey.sAXLPERP]: '3200',
        [FuturesMarketKey.sAAVEPERP]: '3300',
        [FuturesMarketKey.sADAPERP]: '3400',
        [FuturesMarketKey.sALGOPERP]: '3500',
        [FuturesMarketKey.sAPTPERP]: '3600',
        [FuturesMarketKey.sATOMPERP]: '3700',
        [FuturesMarketKey.sAXSPERP]: '3800',
        [FuturesMarketKey.sBALPERP]: '3900',
        [FuturesMarketKey.sBCHPERP]: '4000',
        [FuturesMarketKey.sBLURPERP]: '4100',
        [FuturesMarketKey.sCOMPPERP]: '4200',
        [FuturesMarketKey.sCRVPERP]: '4300',
        [FuturesMarketKey.sDOTPERP]: '4400',
        [FuturesMarketKey.sDYDXPERP]: '4500',
        [FuturesMarketKey.sEOSPERP]: '4600',
        [FuturesMarketKey.sETCPERP]: '4700',
        [FuturesMarketKey.sETHBTCPERP]: '4800',
        [FuturesMarketKey.sFILPERP]: '4900',
        [FuturesMarketKey.sFLOWPERP]: '5000',
        [FuturesMarketKey.sFXSPERP]: '5100',
        [FuturesMarketKey.sGRTPERP]: '5200',
        [FuturesMarketKey.sICPPERP]: '5300',
        [FuturesMarketKey.sIMXPERP]: '5400',
        [FuturesMarketKey.sJTOPERP]: '5500',
        [FuturesMarketKey.sJUPPERP]: '5600',
        [FuturesMarketKey.sLDOPERP]: '5700',
        [FuturesMarketKey.sLTCPERP]: '5800',
        [FuturesMarketKey.sMEMEPERP]: '6000',
        [FuturesMarketKey.sNEARPERP]: '6100',
        [FuturesMarketKey.sPYTHPERP]: '6200',
        [FuturesMarketKey.sRNDRPERP]: '6300',
        [FuturesMarketKey.sSEIPERP]: '6400',
        [FuturesMarketKey.sSHIBPERP]: '6500',
        [FuturesMarketKey.sSTRKPERP]: '6600',
        [FuturesMarketKey.sSUSHIPERP]: '6700',
        [FuturesMarketKey.sTRXPERP]: '6800',
        [FuturesMarketKey.sUNIPERP]: '6900',
        [FuturesMarketKey.sXLMPERP]: '7000',
        [FuturesMarketKey.sXRPPERP]: '7100',
        [FuturesMarketKey.sYFIPERP]: '7200',
        [FuturesMarketKey.sEIGENPERP]: '7300',
        [FuturesMarketKey.sIOPERP]: '7400',
        [FuturesMarketKey.sMEWPERP]: '7500',
        [FuturesMarketKey.sMKRPERP]: '7600',
        [FuturesMarketKey.sNOTPERP]: '7700',
        [FuturesMarketKey.sPEOPLEPERP]: '7800',
        [FuturesMarketKey.sPOPCATPERP]: '8000',
        [FuturesMarketKey.sRENDERPERP]: '8100',
        [FuturesMarketKey.sSATSPERP]: '8200',
        [FuturesMarketKey.sWLDPERP]: '8300',
        [FuturesMarketKey.sZROPERP]: '8400',
    },
    [PerpsProvider.SNX_V3_ARB]: {
        [FuturesMarketKey.sETHPERP]: '100',
        [FuturesMarketKey.sBTCPERP]: '200',
        [FuturesMarketKey.sSOLPERP]: '300',
        [FuturesMarketKey.sWIFPERP]: '400',
        [FuturesMarketKey.sLINKPERP]: '500',
        [FuturesMarketKey.sARBPERP]: '600',
        [FuturesMarketKey.sDOGEPERP]: '700',
        [FuturesMarketKey.sGMXPERP]: '800',
        [FuturesMarketKey.sAAVEPERP]: '900',
        [FuturesMarketKey.sNEARPERP]: '1000',
        [FuturesMarketKey.sUNIPERP]: '1100',
        [FuturesMarketKey.sXRPPERP]: '1200',
        [FuturesMarketKey.sLTCPERP]: '1300',
        [FuturesMarketKey.sORDIPERP]: '1400',
        [FuturesMarketKey.sSTXPERP]: '1500',
        [FuturesMarketKey.sSHIBPERP]: '1600',
        [FuturesMarketKey.sBNBPERP]: '1700',
        [FuturesMarketKey.sAVAXPERP]: '1800',
        [FuturesMarketKey.sPEPEPERP]: '1900',
        [FuturesMarketKey.sOPPERP]: '2000',
        [FuturesMarketKey.sRUNEPERP]: '2100',
        [FuturesMarketKey.sPYTHPERP]: '2200',
        [FuturesMarketKey.sCRVPERP]: '2300',
        [FuturesMarketKey.sTIAPERP]: '2400',
        [FuturesMarketKey.sADAPERP]: '2500',
        [FuturesMarketKey.sBCHPERP]: '2600',
        [FuturesMarketKey.sDYDXPERP]: '2700',
        [FuturesMarketKey.sPOLPERP]: '2800',
        [FuturesMarketKey.sMKRPERP]: '2900',
        [FuturesMarketKey.sXLMPERP]: '3000',
        [FuturesMarketKey.sALGOPERP]: '3100',
        [FuturesMarketKey.sAPTPERP]: '3200',
        [FuturesMarketKey.sARKMPERP]: '3300',
        [FuturesMarketKey.sATOMPERP]: '3400',
        [FuturesMarketKey.sAXLPERP]: '3500',
        [FuturesMarketKey.sAXSPERP]: '3600',
        [FuturesMarketKey.sBALPERP]: '3700',
        [FuturesMarketKey.sBLURPERP]: '3800',
        [FuturesMarketKey.sBOMEPERP]: '3900',
        [FuturesMarketKey.sBONKPERP]: '4000',
        [FuturesMarketKey.sCOMPPERP]: '4100',
        [FuturesMarketKey.sDOTPERP]: '4200',
        [FuturesMarketKey.sENAPERP]: '4300',
        [FuturesMarketKey.sEOSPERP]: '4400',
        [FuturesMarketKey.sETCPERP]: '4500',
        [FuturesMarketKey.sETHBTCPERP]: '4600',
        [FuturesMarketKey.sETHFIPERP]: '4700',
        [FuturesMarketKey.sFILPERP]: '4800',
        [FuturesMarketKey.sFLOWPERP]: '4900',
        [FuturesMarketKey.sFTMPERP]: '5000',
        [FuturesMarketKey.sFXSPERP]: '5100',
        [FuturesMarketKey.sGALAPERP]: '5200',
        [FuturesMarketKey.sGRTPERP]: '5300',
        [FuturesMarketKey.sICPPERP]: '5400',
        [FuturesMarketKey.sIMXPERP]: '5500',
        [FuturesMarketKey.sINJPERP]: '5600',
        [FuturesMarketKey.sJTOPERP]: '5700',
        [FuturesMarketKey.sJUPPERP]: '5800',
        [FuturesMarketKey.sLDOPERP]: '5900',
        [FuturesMarketKey.sMEMEPERP]: '6000',
        [FuturesMarketKey.sPENDLEPERP]: '6100',
        [FuturesMarketKey.sSEIPERP]: '6200',
        [FuturesMarketKey.sSNXPERP]: '6300',
        [FuturesMarketKey.sSTRKPERP]: '6400',
        [FuturesMarketKey.sSUIPERP]: '6500',
        [FuturesMarketKey.sSUSHIPERP]: '6600',
        [FuturesMarketKey.sTAOPERP]: '6700',
        [FuturesMarketKey.sTONPERP]: '6800',
        [FuturesMarketKey.sTRXPERP]: '6900',
        [FuturesMarketKey.sWPERP]: '7000',
        [FuturesMarketKey.sYFIPERP]: '7100',
        [FuturesMarketKey.sMEWPERP]: '7200',
        [FuturesMarketKey.sPOPCATPERP]: '7300',
        [FuturesMarketKey.sEIGENPERP]: '7400',
        [FuturesMarketKey.sWLDPERP]: '7500',
        [FuturesMarketKey.sPEOPLEPERP]: '7600',
        [FuturesMarketKey.sZROPERP]: '7700',
        [FuturesMarketKey.sRENDERPERP]: '7800',
        [FuturesMarketKey.sNOTPERP]: '7900',
        [FuturesMarketKey.sSATSPERP]: '8000',
        [FuturesMarketKey.sIOPERP]: '8100',
    },
};
export const PREDEFINED_ORACLES_BY_MARKET_ID = {
    [FuturesMarketKey.sETHPERP]: PERPS_PYTH_IDS.sETH,
    [FuturesMarketKey.sBTCPERP]: PERPS_PYTH_IDS.sBTC,
    [FuturesMarketKey.sSNXPERP]: PERPS_PYTH_IDS.SNX,
    [FuturesMarketKey.sSOLPERP]: PERPS_PYTH_IDS.SOL,
    [FuturesMarketKey.sWIFPERP]: PERPS_PYTH_IDS.WIF,
    [FuturesMarketKey.sWPERP]: PERPS_PYTH_IDS.W,
    [FuturesMarketKey.sARBPERP]: PERPS_PYTH_IDS.ARB,
    [FuturesMarketKey.sAVAXPERP]: PERPS_PYTH_IDS.AVAX,
    [FuturesMarketKey.sBNBPERP]: PERPS_PYTH_IDS.BNB,
    [FuturesMarketKey.sBONKPERP]: PERPS_PYTH_IDS.BONK,
    [FuturesMarketKey.sDOGEPERP]: PERPS_PYTH_IDS.DOGE,
    [FuturesMarketKey.sENAPERP]: PERPS_PYTH_IDS.ENA,
    [FuturesMarketKey.sFTMPERP]: PERPS_PYTH_IDS.FTM,
    [FuturesMarketKey.sPOLPERP]: PERPS_PYTH_IDS.POL,
    [FuturesMarketKey.sOPPERP]: PERPS_PYTH_IDS.OP,
    [FuturesMarketKey.sORDIPERP]: PERPS_PYTH_IDS.ORDI,
    [FuturesMarketKey.sPEPEPERP]: PERPS_PYTH_IDS.PEPE,
    [FuturesMarketKey.sRUNEPERP]: PERPS_PYTH_IDS.RUNE,
    [FuturesMarketKey.sLINKPERP]: PERPS_PYTH_IDS.LINK,
    [FuturesMarketKey.sPENDLEPERP]: PERPS_PYTH_IDS.PENDLE,
    [FuturesMarketKey.sINJPERP]: PERPS_PYTH_IDS.INJ,
    [FuturesMarketKey.sGMXPERP]: PERPS_PYTH_IDS.GMX,
    [FuturesMarketKey.sTIAPERP]: PERPS_PYTH_IDS.TIA,
    [FuturesMarketKey.sSUIPERP]: PERPS_PYTH_IDS.SUI,
    [FuturesMarketKey.sTONPERP]: PERPS_PYTH_IDS.TON,
    [FuturesMarketKey.sARKMPERP]: PERPS_PYTH_IDS.ARKM,
    [FuturesMarketKey.sGALAPERP]: PERPS_PYTH_IDS.GALA,
    [FuturesMarketKey.sTAOPERP]: PERPS_PYTH_IDS.TAO,
    [FuturesMarketKey.sBOMEPERP]: PERPS_PYTH_IDS.BOME,
    [FuturesMarketKey.sETHFIPERP]: PERPS_PYTH_IDS.ETHFI,
    [FuturesMarketKey.sSTXPERP]: PERPS_PYTH_IDS.STX,
    [FuturesMarketKey.sAXLPERP]: PERPS_PYTH_IDS.AXL,
    [FuturesMarketKey.sAAVEPERP]: PERPS_PYTH_IDS.AAVE,
    [FuturesMarketKey.sADAPERP]: PERPS_PYTH_IDS.ADA,
    [FuturesMarketKey.sALGOPERP]: PERPS_PYTH_IDS.ALGO,
    [FuturesMarketKey.sAPTPERP]: PERPS_PYTH_IDS.APT,
    [FuturesMarketKey.sATOMPERP]: PERPS_PYTH_IDS.ATOM,
    [FuturesMarketKey.sAXSPERP]: PERPS_PYTH_IDS.AXS,
    [FuturesMarketKey.sBALPERP]: PERPS_PYTH_IDS.BAL,
    [FuturesMarketKey.sBCHPERP]: PERPS_PYTH_IDS.BCH,
    [FuturesMarketKey.sBLURPERP]: PERPS_PYTH_IDS.BLUR,
    [FuturesMarketKey.sCOMPPERP]: PERPS_PYTH_IDS.COMP,
    [FuturesMarketKey.sCRVPERP]: PERPS_PYTH_IDS.CRV,
    [FuturesMarketKey.sDOTPERP]: PERPS_PYTH_IDS.DOT,
    [FuturesMarketKey.sDYDXPERP]: PERPS_PYTH_IDS.DYDX,
    [FuturesMarketKey.sEOSPERP]: PERPS_PYTH_IDS.EOS,
    [FuturesMarketKey.sETCPERP]: PERPS_PYTH_IDS.ETC,
    [FuturesMarketKey.sETHBTCPERP]: PERPS_PYTH_IDS.ETHBTC,
    [FuturesMarketKey.sFILPERP]: PERPS_PYTH_IDS.FIL,
    [FuturesMarketKey.sFLOWPERP]: PERPS_PYTH_IDS.FLOW,
    [FuturesMarketKey.sFXSPERP]: PERPS_PYTH_IDS.FXS,
    [FuturesMarketKey.sGRTPERP]: PERPS_PYTH_IDS.GRT,
    [FuturesMarketKey.sICPPERP]: PERPS_PYTH_IDS.ICP,
    [FuturesMarketKey.sIMXPERP]: PERPS_PYTH_IDS.IMX,
    [FuturesMarketKey.sJTOPERP]: PERPS_PYTH_IDS.JTO,
    [FuturesMarketKey.sJUPPERP]: PERPS_PYTH_IDS.JUP,
    [FuturesMarketKey.sLDOPERP]: PERPS_PYTH_IDS.LDO,
    [FuturesMarketKey.sLTCPERP]: PERPS_PYTH_IDS.LTC,
    [FuturesMarketKey.sMEMEPERP]: PERPS_PYTH_IDS.MEME,
    [FuturesMarketKey.sNEARPERP]: PERPS_PYTH_IDS.NEAR,
    [FuturesMarketKey.sPYTHPERP]: PERPS_PYTH_IDS.PYTH,
    [FuturesMarketKey.sSEIPERP]: PERPS_PYTH_IDS.SEI,
    [FuturesMarketKey.sSHIBPERP]: PERPS_PYTH_IDS.SHIB,
    [FuturesMarketKey.sSTRKPERP]: PERPS_PYTH_IDS.STRK,
    [FuturesMarketKey.sSUSHIPERP]: PERPS_PYTH_IDS.SUSHI,
    [FuturesMarketKey.sTRXPERP]: PERPS_PYTH_IDS.TRX,
    [FuturesMarketKey.sUNIPERP]: PERPS_PYTH_IDS.UNI,
    [FuturesMarketKey.sXLMPERP]: PERPS_PYTH_IDS.XLM,
    [FuturesMarketKey.sXRPPERP]: PERPS_PYTH_IDS.XRP,
    [FuturesMarketKey.sYFIPERP]: PERPS_PYTH_IDS.YFI,
};
export const PREDEFINED_ORACLES = [
    ...Object.values(PREDEFINED_ORACLES_BY_MARKET_ID),
    ...Object.values(COLLATERAL_PYTH_IDS),
];
export const V3_SYNTH_KEYS_BASE = Object.keys(V3_WRAPPED_TOKEN_MARKETS[SupportedNetworkIds.BASE_MAINNET]);
export const V3_SYNTH_KEYS_ARB = Object.keys(V3_WRAPPED_TOKEN_MARKETS[SupportedNetworkIds.ARB_MAINNET]);
export const V3_SYNTH_KEYS = [...new Set([...V3_SYNTH_KEYS_BASE, ...V3_SYNTH_KEYS_ARB])];
export const V3_PERPS_MARKET_IDS_TO_KEYS = {
    [PerpsProvider.SNX_V3_BASE]: inverseObj(V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_BASE]),
    [PerpsProvider.SNX_V3_ARB]: inverseObj(V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_ARB]),
};
export const v3PerpsMarketIdToAssetKey = (chainId, marketId) => {
    const key = V3_PERPS_MARKET_IDS_TO_KEYS[chainToV3Provider(chainId)][marketId];
    return MarketAssetByKey[key];
};
export const v3MarketIdByMarketAsset = (chainId, asset) => {
    const provider = chainToV3Provider(chainId);
    if (provider === PerpsProvider.SNX_V3_ARB) {
        const key = MarketKeyByAsset[asset];
        return V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_ARB][key];
    }
    else {
        const key = MarketKeyByAsset[asset];
        return V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_BASE][key];
    }
};
export const v3PythIdByMarketId = (chainId, marketId) => {
    const key = V3_PERPS_MARKET_IDS_TO_KEYS[chainToV3Provider(chainId)][marketId];
    return PREDEFINED_ORACLES_BY_MARKET_ID[key];
};
export const v3SynthIdToKey = (id, chainId) => {
    const record = V3_WRAPPED_TOKEN_MARKETS[chainId];
    const mapping = inverseObj(record);
    if (chainId === SnxV3NetworkIds.BASE_MAINNET || chainId === SnxV3NetworkIds.BASE_SEPOLIA) {
        mapping[0] = SynthAssetKeysV3.sUSDC;
    }
    return mapping[id];
};
export const assetToTokenTicker = (asset) => {
    if (asset === 'sETH')
        return 'ETH';
    if (asset === 'sBTC')
        return 'tBTC';
    return asset;
};
export const ORDERS_KEEPER_ADDRESSES = {
    84532: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    8453: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    421614: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    42161: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
};
export const KWENTA_PERPS_V3_REFERRAL_ADDRESS = {
    [PerpsProvider.SNX_V3_BASE]: '0x3bD64247d879AF879e6f6e62F81430186391Bdb8',
    [PerpsProvider.SNX_V3_ARB]: '0x1813505eDB82a5c0Fc39DDB13213aC530Eb5DB6e',
};
export var DepositableV3AssetsBase;
(function (DepositableV3AssetsBase) {
    DepositableV3AssetsBase["USDC"] = "USDC";
})(DepositableV3AssetsBase || (DepositableV3AssetsBase = {}));
export var DepositableV3AssetsArb;
(function (DepositableV3AssetsArb) {
    // USDC = 'USDC', - Disabled for now due to peg issues
    DepositableV3AssetsArb["USDx"] = "USDx";
    DepositableV3AssetsArb["ETH"] = "ETH";
    DepositableV3AssetsArb["WETH"] = "WETH";
    DepositableV3AssetsArb["tBTC"] = "tBTC";
    DepositableV3AssetsArb["USDe"] = "USDe";
    // wSOL = 'wSOL', // Disabled due to liquidity
})(DepositableV3AssetsArb || (DepositableV3AssetsArb = {}));
export const DepositableAssetKeysV3 = { ...DepositableV3AssetsBase, ...DepositableV3AssetsArb };
export const WRAP_SLIPPAGE_MULTIPLIER = 0.999;
export const UNISWAP_FEE_500 = 500;
export const UNISWAP_FEE_100 = 100;
export const UNISWAP_POOL_FEES = {
    [SnxV3NetworkIds.ARB_MAINNET]: {
        [COMMON_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [COMMON_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [COMMON_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
    [SnxV3NetworkIds.ARB_SEPOLIA]: {
        // TODO:
        [COMMON_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [COMMON_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [COMMON_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
    [SnxV3NetworkIds.BASE_MAINNET]: {
        // TODO:
        [COMMON_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [COMMON_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [COMMON_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
    [SnxV3NetworkIds.BASE_SEPOLIA]: {
        // TODO:
        [COMMON_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [COMMON_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [COMMON_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
};
// https://github.com/JaredBorders/zap/blob/dd9ea4a4f93acd4a502ab3638971f4fc88a86553/src/Zap.sol#L561
export const ZAP_LOAN_BUFFER = wei(1);
