import { PerpsProvider, type SnxV2NetworkIds } from '@kwenta/sdk/types'
import { useEffect } from 'react'

import { fetchBalancesAndAllowances } from 'state/balances/actions'
import { fetchEarnTokenPrices } from 'state/earn/actions'
import { selectPerpsProvider, selectProviderNetwork } from 'state/futures/common/selectors'
import { useAppDispatch, useAppSelector, usePollAction } from 'state/hooks'
import { fetchLatestUserActivities, fetchPoints } from 'state/points/actions'
import { fetchPreviousDayPrices, fetchStableCoinPrices, updatePrices } from 'state/prices/actions'
import { setConnectionError } from 'state/prices/reducer'
import sdk from 'state/sdk'
import { selectWallet } from 'state/wallet/selectors'
import { serializePrices } from 'utils/futures'

import { checkSynthetixStatus } from './actions'

export function useAppData(ready: boolean) {
	const dispatch = useAppDispatch()
	const wallet = useAppSelector(selectWallet)
	const network = useAppSelector(selectProviderNetwork)
	const perpsProvider = useAppSelector(selectPerpsProvider)

	usePollAction('fetchEarnTokenPrices', fetchEarnTokenPrices, {
		intervalTime: 60000 * 10,
		dependencies: [wallet],
		disabled: !wallet,
	})

	usePollAction('fetchBalances', fetchBalancesAndAllowances, { dependencies: [wallet, network] })

	usePollAction('fetchLatestUserActivities', fetchLatestUserActivities, {
		intervalTime: 1000 * 5,
		dependencies: [wallet],
	})

	usePollAction('fetchPoints', fetchPoints, {
		dependencies: [wallet],
	})

	usePollAction('fetchPreviousDayPrices', fetchPreviousDayPrices, {
		intervalTime: 60000 * 15,
	})

	usePollAction('checkSynthetixStatus', checkSynthetixStatus, {
		intervalTime: 2 * 60 * 1000,
		dependencies: [network],
	})

	usePollAction('fetchStableCoinPrices', fetchStableCoinPrices, {
		intervalTime: 60 * 1000,
	})

	useEffect(() => {
		if (ready && perpsProvider === PerpsProvider.SNX_V2_OP) {
			sdk.prices.startOnchainPriceUpdates(15000, network as SnxV2NetworkIds)
		} else if (ready && perpsProvider !== PerpsProvider.SNX_V2_OP) {
			sdk.prices.stopOnchainPriceUpdates()
		}
	}, [ready, perpsProvider, network])

	useEffect(() => {
		sdk.prices.onPricesUpdated(({ prices, type, source }) => {
			dispatch(updatePrices(serializePrices(prices), type))
			if (source === 'stream') {
				// must be connected again, remove any error
				dispatch(setConnectionError(null))
			}
		})

		sdk.prices.onPricesConnectionUpdated(({ error }) => {
			dispatch(setConnectionError(error?.message))
		})

		return () => {
			sdk.prices.removePricesListeners()
			sdk.prices.removeConnectionListeners()
		}
	}, [dispatch])
}
