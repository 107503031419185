import {
	COMMON_ADDRESSES,
	DEFAULT_PRICE_IMPACT_DELTA_PERCENT,
	type DepositableV3Assets,
	DepositableV3AssetsArb,
	DepositableV3AssetsBase,
	PERIOD_IN_SECONDS,
	SL_TP_MAX_SIZE_CROSS_MARGIN,
	SNX_V3_PERPS_ADDRESSES,
	type SynthAssetKeysV3,
	ZERO_WEI,
} from '@kwenta/sdk/constants'
import type { DepositableAssetKeysV3Type } from '@kwenta/sdk/constants'
import {
	type ConditionalOrderInput,
	ConditionalOrderStatusV3,
	FuturesMarginType,
	type NetworkId,
	OrderTypeEnum,
	Period,
	PerpsProvider,
	PositionSide,
	RawCondition,
	type SendTransactionParameters,
	type SnxV3NetworkIds,
	TransactionStatus,
	type WriteContractParameters,
} from '@kwenta/sdk/types'
import {
	DepositTokenToSynth,
	calculateDesiredFillPrice,
	depositableAssetToToken,
	floorNumber,
	isSimulatedRequest,
	usdcDecimals,
} from '@kwenta/sdk/utils'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { ConditionOrderTableItem } from 'types/futures'
import { formatUnits, isHash } from 'viem'

import { notifyError } from 'components/ErrorNotifier'
import {
	monitorAndAwaitTransaction,
	monitorAndAwaitUserOp,
	monitorFollowingTransaction,
} from 'state/app/helpers'
import {
	handleTransactionError,
	setOpenModal,
	setShowConditionalOrderModal,
	setShowEditPositionModal,
	setTransaction,
	updateTransactionStatus,
	updateTransactionStep,
} from 'state/app/reducer'
import { fetchBalancesAndAllowances } from 'state/balances/actions'
import { selectTokenAllowances } from 'state/balances/selectors'
import { KEEPER_USD_GAS_FEE } from 'state/constants'
import {
	selectAccountContext,
	selectActivePositions,
	selectClosePositionPreview,
	selectEditPositionModalInfo,
	selectEditPositionPreview,
} from 'state/futures/selectors'
import { handleFetchError } from 'state/helpers'
import { selectPrices } from 'state/prices/selectors'
import type { AppThunk } from 'state/store'
import { FetchStatus, type ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import { currentTimestamp } from 'utils/date'
import {
	orderConditionFromTargetPrice,
	orderPriceInvalidLabel,
	providerIsCrossMargin,
	serializeTransactionOrder,
	serializeV3Liquidations,
} from 'utils/futures'
import logError from 'utils/logError'

import {
	editClosePositionPrice,
	editClosePositionSizeDelta,
	fetchAccountData,
	fetchOpenConditionalOrders,
	fetchPendingMarketOrders,
	fetchPerpsAccounts,
	stageTradePreview,
} from '../actions'
import { submitFuturesTransaction } from '../common/actions'
import {
	selectClosePositionOrderInputs,
	selectEditCOModalInputs,
	selectEditPositionInputs,
	selectLeverageSide,
	selectMarketIndexPrice,
	selectPerpsProvider,
	selectSlTpModalInputs,
	selectSnxV3ArbNetwork,
	selectSnxV3Network,
	selectSnxV3Provider,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectTradePanelOrderPriceInput,
	selectV3Markets,
} from '../common/selectors'
import type { SnxPerpsV3TradePreviewParams } from '../common/types'
import {
	clearTradePreview,
	handlePreviewError,
	setAbstractionUsdcEngineAllowance,
	setAccount,
	setClosePositionPrice,
	setClosePositionSizeDelta,
	setConditionalOrderPriceInvalidLabel,
	setEditConditonalOrderModalPrice,
	setEditConditonalOrderModalSize,
	setEditPositionInputs,
	setGlobalLiquidationHistory,
	setLeverageInput,
	setOrderPriceInvalidLabel,
	setQueryStatus,
	setSnxV3DebtPaymentQuote,
	setSnxV3MaxDepositAmounts,
	setSupportedCollaterals,
	setTradeInputs,
	setTradePanelOrderPrice,
	setTradePreview,
	setTradeStopLoss,
	setTradeTakeProfit,
	updateAccountData,
} from '../reducer'
import {
	selectAllSnxV3SLTPOrders,
	selectCrossMarginConditionalOrders,
	selectCrossMarginMarginInfo,
	selectCrossMarginTradePreview,
	selectCrossMarginTradeableMargin,
	selectDebtPaymentQuote,
	selectDepositAllowances,
	selectMarginEnginePermitted,
	selectMargineEngineEnabled,
	selectMarkPricesV3,
	selectProviderSupportsZap,
	selectSnxV3Account,
	selectSnxV3AccountContext,
	selectV3SelectedMarketId,
	selectV3SelectedMarketInfo,
	selectV3SkewAdjustedPrice,
	selectV3SynthPrices,
} from './selectors'

export const fetchKeeperBalance = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchKeeperBalance',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { accountId, network, wallet, provider } = selectSnxV3AccountContext(getState())
		const marginEngineEnabled = selectMargineEngineEnabled(getState())

		if (!accountId || !wallet || !marginEngineEnabled) return
		try {
			dispatch(setQueryStatus({ key: 'get_keeper_balance', status: FetchStatus.Loading }))
			const balance = await sdk.snxPerpsV3.getAccountUsdcCredit(BigInt(accountId), network)
			dispatch(
				updateAccountData({
					wallet,
					data: {
						network,
						account: accountId.toString(),
						usdcBalance: balance.toString(),
						provider: provider,
					},
				})
			)
			dispatch(setQueryStatus({ key: 'get_keeper_balance', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_keeper_balance', err)
		}
	}
)

export const fetchPerpsV3Balances = createAsyncThunk<void, void, ThunkConfig>(
	'balances/fetchPerpsV3Balances',
	async (_, { dispatch }) => {
		dispatch(fetchBalancesAndAllowances())
		dispatch(fetchMaxDepositAmounts())
		dispatch(fetchKeeperBalance())
	}
)

export const fetchSupportedCollaterals = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchSupportedCollaterals',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		try {
			const network = selectSnxV3ArbNetwork(getState())
			dispatch(setQueryStatus({ key: 'get_supported_collaterals', status: FetchStatus.Loading }))
			const collaterals = await sdk.snxPerpsV3.getSupportedCollaterals(network)
			dispatch(setSupportedCollaterals({ provider: PerpsProvider.SNX_V3_ARB, collaterals }))
			dispatch(setQueryStatus({ key: 'get_supported_collaterals', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_supported_collaterals', err)
		}
	}
)

export const fetchCrossMarginAccountLiquidations = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchCrossMarginAccountLiquidations',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		try {
			const { wallet, network, accountId, provider } = selectSnxV3AccountContext(getState())
			if (!wallet || !accountId) return
			dispatch(setQueryStatus({ key: 'get_account_liquidations', status: FetchStatus.Loading }))
			const liquidations = await sdk.snxPerpsV3.getAllAccountLiquidations(
				BigInt(accountId),
				network
			)
			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider: provider,
						account: accountId.toString(),
						network,
						liquidations: serializeV3Liquidations(liquidations),
					},
				})
			)
			dispatch(setQueryStatus({ key: 'get_account_liquidations', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_account_liquidations', err)
		}
	}
)

export const fetchPerpsV3GlobalLiquidations = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchPerpsV3GlobalLiquidations',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		try {
			const { wallet, accountId, provider } = selectSnxV3AccountContext(getState())
			const marketId = selectV3SelectedMarketId(getState())
			const chain = selectSnxV3Network(getState())
			if (!marketId || !wallet || !accountId) return
			const maxTimestamp = currentTimestamp()
			const minTimestamp = 0
			dispatch(setQueryStatus({ key: 'get_global_liquidations', status: FetchStatus.Loading }))
			const liquidations = await sdk.snxPerpsV3.getAllLiquidationsByMarket(marketId, chain, {
				minTimestamp,
				maxTimestamp,
			})
			dispatch(
				setGlobalLiquidationHistory({
					marketId,
					liqHistory: serializeV3Liquidations(liquidations),
					provider: provider,
				})
			)
			dispatch(setQueryStatus({ key: 'get_global_liquidations', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_global_liquidations', err)
		}
	}
)

type CollateralBalancesMap = Partial<Record<SynthAssetKeysV3, string>>

export const fetchAccountCollateralBalances = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchAccountCollateralBalances',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { wallet, network, accountId, provider } = selectSnxV3AccountContext(getState())

		if (!wallet || !accountId) return
		try {
			dispatch(setQueryStatus({ key: 'get_collateral_balances', status: FetchStatus.Loading }))
			const balances = await sdk.snxPerpsV3.getCollateralBalances(BigInt(accountId), network)
			const balancesMap = balances.reduce<CollateralBalancesMap>((acc, cur) => {
				acc[cur.synthId] = cur.amount.toString()
				return acc
			}, {})
			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider: provider,
						account: accountId.toString(),
						network,
						collateralBalances: balancesMap,
					},
				})
			)
			dispatch(setQueryStatus({ key: 'get_collateral_balances', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_collateral_balances', err)
		}
	}
)

export const fetchSnxV3AccountData = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchSnxV3AccountData',
	async (_, { dispatch, getState }) => {
		const { provider } = selectSnxV3AccountContext(getState())
		dispatch(fetchAccountData([provider]))
	}
)

export const removeStaleOrders = createAsyncThunk<void, void, ThunkConfig>(
	'futures/removeStaleOrders',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { accountId, network, provider } = selectSnxV3AccountContext(getState())
		if (accountId) {
			await sdk.snxPerpsV3.removeStaleConditionalOrders(BigInt(accountId), network)
			dispatch(fetchOpenConditionalOrders([provider]))
		}
	}
)

export const fetchMarginSnapshotsV3 = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchMarginSnapshotsV3',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			network: networkId,
			accountId,
			wallet,
			provider,
		} = selectSnxV3AccountContext(getState())
		if (!wallet || !accountId) return

		try {
			dispatch(setQueryStatus({ key: 'get_margin_snapshots', status: FetchStatus.Loading }))
			const yearSnapshots = await sdk.snxPerpsV3.getMarginSnapshots(BigInt(accountId), networkId, {
				resolution: '1h',
				fromTimestamp: Date.now() / 1000 - PERIOD_IN_SECONDS.ONE_YEAR,
				toTimestamp: Date.now() / 1000,
			})

			const monthSnapshots = await sdk.snxPerpsV3.getMarginSnapshots(BigInt(accountId), networkId, {
				resolution: '4h',
				fromTimestamp: Date.now() / 1000 - PERIOD_IN_SECONDS.ONE_MONTH,
				toTimestamp: Date.now() / 1000,
			})

			const weekSnapshots = await sdk.snxPerpsV3.getMarginSnapshots(BigInt(accountId), networkId, {
				resolution: '1h',
				fromTimestamp: Date.now() / 1000 - PERIOD_IN_SECONDS.ONE_WEEK,
				toTimestamp: Date.now() / 1000,
			})

			dispatch(
				updateAccountData({
					wallet,
					data: {
						network: networkId,
						provider: provider,
						account: accountId.toString(),
						marginSnapshots: {
							[Period.ONE_YEAR]: yearSnapshots.map((s) => ({
								...s,
								margin: s.margin.toString(),
							})),
							[Period.ONE_MONTH]: monthSnapshots.map((s) => ({
								...s,
								margin: s.margin.toString(),
							})),
							[Period.ONE_WEEK]: weekSnapshots.map((s) => ({
								...s,
								margin: s.margin.toString(),
							})),
						},
					},
				})
			)
			dispatch(setQueryStatus({ key: 'get_margin_snapshots', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_margin_snapshots', err)
		}
	}
)

export const updateV3ConditionalOrder = createAsyncThunk<
	void,
	{
		order: ConditionOrderTableItem
		sizeDelta: Wei
		targetPrice: Wei
	},
	ThunkConfig
>(
	'futures/updateConditionalOrder',
	async ({ order, sizeDelta, targetPrice }, { getState, dispatch, extra: { sdk } }) => {
		const { network: networkId, accountId, provider } = selectSnxV3AccountContext(getState())
		const prices = selectMarkPricesV3(getState())
		const markets = selectV3Markets(getState())

		const market = markets.find((m) => m.asset === order.asset)
		if (!market) throw new Error('No market found')
		const price = prices[order.asset]
		if (!price) throw new Error(`Missing price data for market ${order.asset}`)
		if (!accountId) return

		if (order.nonce === undefined) throw new Error('No nonce for existing order')

		const orderCondition = orderConditionFromTargetPrice(targetPrice, price)
		try {
			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
				})
			)
			await sdk.snxPerpsV3.updateConditionalOrders(
				[
					{
						orderId: order.id,
						nonce: order.nonce,
						inputs: {
							marketId: market.marketId,
							networkId: networkId,
							accountId: BigInt(accountId),
							settlementStrategyId: market.settlementStrategies[0].strategyId,
							sizeDelta: sizeDelta.toBigInt(),
							acceptablePrice: targetPrice.toBigInt(),
							isReduceOnly: order.reduceOnly,
							maxExecutorFee: order.maxExecutorFee.toBigInt(),
							rawConditions: {
								[orderCondition]: targetPrice.toBigInt(),
							},
						},
					},
				],
				networkId
			)
			dispatch(fetchOpenConditionalOrders([provider]))
		} catch (err) {
			logError(err)
			notifyError('Failed to update order', err)
			throw err
		} finally {
			dispatch(setShowConditionalOrderModal(null))
			dispatch(setShowEditPositionModal(null))
		}
	}
)

export const clearCrossMarginTradeInputs = createAsyncThunk<void, void, ThunkConfig>(
	'futures/clearCrossMarginTradeInputs',
	async (_, { dispatch }) => {
		dispatch(setTradeInputs({ nativeSize: '', susdSize: '' }))
		dispatch(clearTradePreview())
		dispatch(setEditPositionInputs({ nativeSizeDelta: '', marginDelta: '' }))
		dispatch(setLeverageInput(''))
		dispatch(setTradeStopLoss(''))
		dispatch(setTradeTakeProfit(''))
	}
)

export const editCrossMarginTradeSize =
	(size: string, currencyType: 'usd' | 'native'): AppThunk =>
	(dispatch, getState) => {
		const indexPrice = selectMarketIndexPrice(getState())
		const tradeSide = selectLeverageSide(getState())
		const marketInfo = selectV3SelectedMarketInfo(getState())
		const tradeableMargin = selectCrossMarginTradeableMargin(getState())
		const orderType = selectTradeOrderType(getState())
		const marketPrice = selectV3SkewAdjustedPrice(getState())
		const orderPrice = selectTradePanelOrderPriceInput(getState())
		const accountId = selectSnxV3Account(getState())

		if (!marketInfo) throw new Error('No market selected')

		if (size === '' || indexPrice.eq(0)) {
			dispatch(setTradeInputs({ nativeSize: '', susdSize: '' }))
			dispatch(setTradePreview({ provider: marketInfo.provider, preview: undefined }))
			dispatch(setLeverageInput(''))
			return
		}

		const nativeSize =
			currencyType === 'native' ? size : String(floorNumber(wei(size).div(indexPrice), 4))
		const usdSize = currencyType === 'native' ? String(floorNumber(indexPrice.mul(size), 4)) : size
		const leverage = tradeableMargin?.gt(0) ? wei(usdSize).div(tradeableMargin.abs()) : '0'
		const sizeDeltaWei =
			tradeSide === PositionSide.LONG ? wei(nativeSize || 0) : wei(nativeSize || 0).neg()

		dispatch(
			setTradeInputs({
				susdSize: usdSize,
				nativeSize: nativeSize,
			})
		)
		dispatch(setLeverageInput(leverage.toString(2)))
		dispatch(
			stageTradePreview({
				provider: marketInfo.provider,
				accountId: accountId?.toString(),
				market: marketInfo,
				orderPrice: wei(orderPrice || marketPrice),
				currentIndexPrice: indexPrice,
				sizeDelta: sizeDeltaWei,
				action: 'trade',
				marketPrice: marketPrice,
				indexPrice: indexPrice,
				isConditional: orderType !== OrderTypeEnum.MARKET,
			})
		)
	}

export const editCrossMarginCloseAmount =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setClosePositionSizeDelta(nativeSizeDelta))
		const provider = selectSnxV3Provider(getState())
		const { price, isConditional } = selectClosePositionOrderInputs(getState())

		if (nativeSizeDelta === '' || !nativeSizeDelta) {
			dispatch(setTradePreview({ provider: provider, preview: undefined }))
			return
		}
		const { market, marketPrice, indexPrice, accountId } = selectEditPositionModalInfo(getState())

		if (
			market?.marginType !== FuturesMarginType.CROSS_MARGIN ||
			!indexPrice ||
			!accountId ||
			(!price?.value && isConditional)
		)
			return

		try {
			const previewParams: SnxPerpsV3TradePreviewParams = {
				provider: provider,
				accountId,
				market: market,
				orderPrice: isConditional && price?.value ? wei(price.value) : undefined,
				currentIndexPrice: indexPrice,
				sizeDelta: wei(nativeSizeDelta),
				action: 'close',
				indexPrice: indexPrice,
				marketPrice: marketPrice,
			}
			dispatch(stageTradePreview(previewParams))
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: provider,
				})
			)
		}
	}

// Contract Mutations

export const createPerpsV3Account = createAsyncThunk<
	{ account: string; wallet: string; network: NetworkId } | undefined,
	void,
	ThunkConfig
>(
	'futures/createPerpsV3Account',
	async (_, { getState, dispatch, extra: { sdk }, rejectWithValue }) => {
		const wallet = selectWallet(getState())
		const networkId = selectSnxV3Network(getState())
		const marginEngineEnabled = selectMargineEngineEnabled(getState())
		const provider = selectPerpsProvider(getState())

		if (!wallet) return undefined
		if (!providerIsCrossMargin(provider)) throw new Error('Invalid perps provider')
		const accounts = getState().futures.accounts[provider]

		// Already have an account fetched and persisted for this address
		if (accounts[wallet]?.network === networkId) {
			notifyError('There is already an account associated with this wallet')
			rejectWithValue('Account already created')
		}

		try {
			const accountIds = await sdk.snxPerpsV3.getAccounts(wallet, marginEngineEnabled, networkId)

			if (accountIds.length > 0) {
				// Already have an account, no need to create one
				const { accountId, marginEnginePermitted } = accountIds[0]
				dispatch(
					setAccount({
						provider: provider,
						account: accountId.toString(),
						marginEnginePermitted,
						wallet: wallet,
						networkId,
					})
				)
				return
			}

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'create_cross_margin_account',
					hash: null,
				})
			)
			const { request } = await sdk.snxPerpsV3.createAccount(networkId)
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				networkId
			)
			await monitorAndAwaitTransaction(networkId, dispatch, txHash)
			dispatch(fetchPerpsAccounts({ providers: [provider] }))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const grantMarginEnginePermission = createAsyncThunk<void, void, ThunkConfig>(
	'futures/grantMarginEnginePermission',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const { provider, network, accountId } = selectSnxV3AccountContext(getState())

		if (!accountId) throw new Error('No account id found')

		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'permit_margin_engine',
					hash: null,
				})
			)
			const { request } = await sdk.snxPerpsV3.grantMarginEnginePermission(
				BigInt(accountId),
				network
			)
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(fetchPerpsAccounts({ providers: [provider], refetch: true }))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const approveCrossMarginDeposit = createAsyncThunk<
	void,
	{ usingOneClick?: boolean; asset: DepositableAssetKeysV3Type; amount?: Wei },
	ThunkConfig
>(
	'futures/approveCrossMarginDeposit',
	async (
		{ usingOneClick = false, asset, amount },
		{ getState, dispatch, extra: { sdk, accountAbstractionFactory } }
	) => {
		const { provider } = selectAccountContext(getState())
		const zapSupported = selectProviderSupportsZap(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const network = selectSnxV3Network(getState())

		const engineContract = SNX_V3_PERPS_ADDRESSES.MarginEngine[network]

		const spender = zapSupported
			? engineContract
			: SNX_V3_PERPS_ADDRESSES.SpotV3MarketProxy[network]

		const tokenAddress = COMMON_ADDRESSES[depositableAssetToToken(asset)][network]

		try {
			if (!tokenAddress) throw new Error(`Token address not found for ${asset}`)
			if (usingOneClick && !accountAbstraction) {
				throw new Error('Account abstraction not available')
			}
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'approve_cross_margin',
					hash: null,
				})
			)

			const { request } = await sdk.tokens.approveTokenSpend({
				address: tokenAddress,
				spender: spender,
				signer: usingOneClick ? accountAbstraction!.accountAddress : undefined,
				chainId: network,
				amount: amount?.toBigInt(),
			})

			if (usingOneClick) {
				if (
					'preparePaymasterData' in accountAbstraction! &&
					typeof accountAbstraction.preparePaymasterData === 'function'
				) {
					const paymasterServiceData = accountAbstraction.preparePaymasterData()
					if (!paymasterServiceData) {
						throw new Error('Paymaster service data not found')
					}

					// It's first transaction, need to approve paymaster too
					paymasterServiceData.skipPatchCallData = false

					const res = await accountAbstraction.sendTransactions({
						simulateTxs: [request],
						options: {
							paymasterServiceData,
						},
					})
					if (typeof res === 'string' && isHash(res)) {
						await monitorAndAwaitTransaction(network, dispatch, res)
					} else {
						await monitorAndAwaitUserOp(dispatch, res)
					}

					dispatch(fetchUsdcAbstractionAllowanceForEngine())
				} else {
					throw new Error('Account abstraction provider doesnt support preparePaymasterData method')
				}
			} else {
				const tx = await sdk.transactions.writeContract(request, network)
				await monitorAndAwaitTransaction(network, dispatch, tx)
			}
			dispatch(fetchPerpsV3Balances())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const depositWrappedTokenCrossMargin = createAsyncThunk<
	void,
	{ amount: Wei; token: DepositableV3Assets; closeOnComplete?: boolean },
	ThunkConfig
>(
	'futures/depositWrappedTokenCrossMargin',
	async ({ amount, token, closeOnComplete }, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network, provider } = selectSnxV3AccountContext(getState())
		const tokenAllowances = selectTokenAllowances(getState())
		const depositAllowances = selectDepositAllowances(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())

		const marginEngineAddress = network ? SNX_V3_PERPS_ADDRESSES.MarginEngine[network] : undefined

		const synth = DepositTokenToSynth[provider][token]

		try {
			if (!synth) throw new Error(`Synth not found for ${token}`)

			const depositToken = depositableAssetToToken(token)

			const tokenAllowance = tokenAllowances[depositToken]

			const useZap = true // TODO: Remove

			const firstSpender = useZap
				? marginEngineAddress
				: SNX_V3_PERPS_ADDRESSES.SpotV3MarketProxy[network]

			const spotMarketAddress = network
				? SNX_V3_PERPS_ADDRESSES.SpotV3MarketProxy[network]
				: undefined
			const perpsMarketAddress = network
				? SNX_V3_PERPS_ADDRESSES.PerpsV3MarketProxy[network]
				: undefined
			const engineAllowance = depositAllowances[depositToken]?.marginEngine ?? wei(0)
			const spotProxyAllowance = depositAllowances[depositToken]?.spotProxy ?? wei(0)
			const marketAllowance = depositAllowances[synth]?.marketProxy ?? wei(0)
			const allowance = useZap ? engineAllowance : spotProxyAllowance

			const decimals = token === 'USDC' ? usdcDecimals(network) : 18
			const firstAllowance = wei(formatUnits(allowance.toBigInt(), decimals))

			if (!accountId) throw new Error('Account id not found')
			if (!tokenAllowance) throw new Error(`Token allowance not found for ${depositToken}`)
			if (!spotMarketAddress || !perpsMarketAddress || !firstSpender)
				throw new Error('Unsupported network')

			if (!firstSpender) throw new Error('Unsupported network')

			if (useZap && !marginEnginePermitted) {
				await dispatch(grantMarginEnginePermission())
			}

			if (firstAllowance?.lt(amount) && token !== DepositableV3AssetsArb.ETH) {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'approve_cross_margin',
						hash: null,
					})
				)
				const address = COMMON_ADDRESSES[depositToken][network]

				if (!address) throw new Error(`Address not found for ${depositToken}`)
				const { request } = await sdk.tokens.approveTokenSpend({
					address: address,
					spender: firstSpender,
					chainId: network,
					amount: amount.toBigInt(),
				})
				const tx = await sdk.transactions.writeContract(request as WriteContractParameters, network)

				await monitorAndAwaitTransaction(network, dispatch, tx)
			}

			if (!useZap && marketAllowance.lt(amount)) {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'deposit_cross_margin',
						hash: null,
					})
				)

				const synthAddress = COMMON_ADDRESSES[synth][network]
				const secondSpender = SNX_V3_PERPS_ADDRESSES.PerpsV3MarketProxy[network]

				if (!synthAddress) throw new Error(`Synth address not found for ${synth}`)
				if (!secondSpender) throw new Error('Unsupported network')

				const { request } = await sdk.tokens.approveTokenSpend({
					address: synthAddress,
					spender: secondSpender,
					chainId: network,
				})
				const tx = await sdk.transactions.writeContract(request as WriteContractParameters, network)

				await monitorAndAwaitTransaction(network, dispatch, tx)
				await dispatch(fetchBalancesAndAllowances())
			}
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_cross_margin',
					hash: null,
				})
			)

			const request = await sdk.snxPerpsV3.depositWrappedToken({
				accountId: BigInt(accountId),
				token: token,
				amountInWei: amount.toBigInt(),
				useMarginEngine: useZap,
				chainId: network,
			})

			const tx = isSimulatedRequest(request)
				? await sdk.transactions.writeContract(request as WriteContractParameters, network)
				: await sdk.transactions.sendTransaction(request as SendTransactionParameters, network)

			await monitorAndAwaitTransaction(network, dispatch, tx)
			if (closeOnComplete) {
				dispatch(setOpenModal(null))
			}
			dispatch(fetchSnxV3AccountData())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const depositCrossMarginMarginUSDx = createAsyncThunk<
	void,
	{ amount: Wei; synthKey: SynthAssetKeysV3; closeOnComplete?: boolean },
	ThunkConfig
>(
	'futures/depositCrossMarginMarginUSDx',
	async ({ amount, synthKey, closeOnComplete }, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV3AccountContext(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		const depositAllowances = selectDepositAllowances(getState())
		const usdxAllowances = depositAllowances[DepositableV3AssetsArb.USDx]

		if (!accountId) throw new Error('Account id not found')
		try {
			if (!marginEnginePermitted) {
				await dispatch(grantMarginEnginePermission())
			}

			if (usdxAllowances?.marginEngine?.lt(amount)) {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'approve_cross_margin',
						hash: null,
					})
				)
				const address = COMMON_ADDRESSES.USDx[network]
				if (!address) throw new Error('Address not found for USDx')
				const { request } = await sdk.tokens.approveTokenSpend({
					address,
					spender: SNX_V3_PERPS_ADDRESSES.MarginEngine[network],
					chainId: network,
					amount: amount.toBigInt(),
				})
				const tx = await sdk.transactions.writeContract(request as WriteContractParameters, network)

				await monitorAndAwaitTransaction(network, dispatch, tx)
				await dispatch(fetchBalancesAndAllowances())
			}

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_cross_margin',
					hash: null,
				})
			)
			const request = await sdk.snxPerpsV3.depositCollateral({
				accountId: BigInt(accountId),
				synthKey,
				amount,
				useMarginEngine: true,
				chainId: network,
			})
			const tx = await sdk.transactions.sendTransaction(request, network)

			await monitorAndAwaitTransaction(network, dispatch, tx)
			if (closeOnComplete) {
				dispatch(setOpenModal(null))
			}
			dispatch(fetchSnxV3AccountData())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const withdrawCrossMargin = createAsyncThunk<
	void,
	{
		amount: Wei
		asset: SynthAssetKeysV3
		unwrapToToken?: DepositableV3Assets
		payDebtWithCollateral: boolean
	},
	ThunkConfig
>(
	'futures/withdrawCrossMargin',
	async (
		{ amount, asset, unwrapToToken, payDebtWithCollateral },
		{ getState, dispatch, extra: { sdk } }
	) => {
		const { accountId, network, provider } = selectSnxV3AccountContext(getState())
		const marginEngineEnabled = selectMargineEngineEnabled(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		const depositAllowances = selectDepositAllowances(getState())
		const { debt } = selectCrossMarginMarginInfo(getState())

		if (!accountId) throw new Error('Account id not found')
		try {
			if (!marginEnginePermitted) {
				await dispatch(grantMarginEnginePermission())
			}
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_cross_margin',
					hash: null,
				})
			)

			const usdxAllowance =
				(marginEngineEnabled
					? depositAllowances.USDx?.marginEngine
					: depositAllowances.USDx?.marketProxy) ?? wei(0)

			if (!payDebtWithCollateral && debt.gt(0) && usdxAllowance.lt(debt)) {
				// Approve transferring USDx to pay down debt with USDx wallet balance
				const address = COMMON_ADDRESSES.USDx[network]
				if (!address) throw new Error('Address not found for USDx')
				const { request } = await sdk.tokens.approveTokenSpend({
					address,
					spender: marginEngineEnabled
						? SNX_V3_PERPS_ADDRESSES.MarginEngine[network]
						: SNX_V3_PERPS_ADDRESSES.PerpsV3MarketProxy[network],
					chainId: network,
					amount: debt.toBigInt(),
				})
				const tx = await sdk.transactions.writeContract(request as WriteContractParameters, network)
				await monitorAndAwaitTransaction(network, dispatch, tx)
			}

			if (unwrapToToken && !marginEngineEnabled) {
				const network = selectSnxV3Network(getState())
				const spotMarketAddress = network
					? SNX_V3_PERPS_ADDRESSES.SpotV3MarketProxy[network]
					: undefined

				const synthSymbol = DepositTokenToSynth[provider][unwrapToToken]

				if (!spotMarketAddress) throw new Error('Unsupported network')
				if (!synthSymbol) throw new Error(`Synth not found for ${unwrapToToken}`)

				const synthTokenAllowance = depositAllowances[synthSymbol]

				if (
					provider === PerpsProvider.SNX_V3_BASE &&
					depositAllowances.USDx?.spotProxy.lt(amount)
				) {
					// Approve swapping sUSD back to USDC on Base
					dispatch(
						setTransaction({
							chainId: network,
							status: TransactionStatus.AwaitingExecution,
							type: 'approve_cross_margin',
							hash: null,
						})
					)
					const address = COMMON_ADDRESSES.USDx[network]
					if (!address) throw new Error('Address not found for USDx')
					const { request } = await sdk.tokens.approveTokenSpend({
						address: address,
						spender: spotMarketAddress,
						chainId: network,
					})
					const tx = await sdk.transactions.writeContract(
						request as WriteContractParameters,
						network
					)

					await monitorAndAwaitTransaction(network, dispatch, tx)
					dispatch(fetchPerpsV3Balances())
				}

				if (!synthTokenAllowance || synthTokenAllowance.spotProxy?.lt(amount)) {
					// Approve unwrapping sToken back to token
					dispatch(
						setTransaction({
							chainId: network,
							status: TransactionStatus.AwaitingExecution,
							type: 'approve_cross_margin',
							hash: null,
						})
					)
					const address = COMMON_ADDRESSES[synthSymbol][network]
					if (!address) throw new Error(`Address not found for ${synthSymbol}`)
					const { request } = await sdk.tokens.approveTokenSpend({
						address: address,
						spender: spotMarketAddress,
						chainId: network,
					})

					const tx = await sdk.transactions.writeContract(
						request as WriteContractParameters,
						network
					)
					await monitorAndAwaitTransaction(network, dispatch, tx)
				}
			}

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_cross_margin',
					hash: null,
				})
			)

			const openPositions = selectActivePositions(getState()).map((p) => p.asset)
			const debtPaymentQuote = selectDebtPaymentQuote(getState())

			if (debt.gt(0) && !debtPaymentQuote?.quotedPrice)
				throw new Error('Missing quote for debt payment')

			const request = unwrapToToken
				? await sdk.snxPerpsV3.withdrawUnwrapped({
						accountId: BigInt(accountId),
						token: unwrapToToken,
						amountWei: amount.toBigInt(),
						useMarginEngine: marginEngineEnabled,
						chainId: network,
						quotedSwapPrice: debtPaymentQuote?.quotedPrice.toBigInt() ?? 0n,
						settleDebtWithCollateral: payDebtWithCollateral,
						maxSwapSlippage: 0.05, // Keeping this loose as traders are warned in the UI what slippage they incur
						openPositions,
					})
				: await sdk.snxPerpsV3.withdrawCollateral({
						accountId: BigInt(accountId),
						synthKey: asset,
						amount,
						useMarginEngine: false,
						chainId: network,
					})

			const txHash = isSimulatedRequest(request)
				? await sdk.transactions.writeContract({ ...request, value: undefined }, network)
				: await sdk.transactions.sendTransaction(request as SendTransactionParameters, network)

			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(fetchSnxV3AccountData())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const quoteDebtPaymentSwap = createAsyncThunk<
	void,
	{ asset: DepositableV3Assets; debtAmount: Wei },
	ThunkConfig
>(
	'futures/quoteDebtPaymentSwap',
	async ({ asset, debtAmount }, { getState, dispatch, extra: { sdk } }) => {
		const { network, provider } = selectSnxV3AccountContext(getState())
		const prices = selectV3SynthPrices(getState())

		try {
			dispatch(setQueryStatus({ key: 'quote_debt_payment', status: FetchStatus.Loading }))

			const assetIn = DepositTokenToSynth[provider][asset]
			if (!assetIn) throw new Error(`Synth not found for ${asset}`)
			const price = prices[assetIn]
			if (!price) throw new Error(`Price not found for ${asset}`)

			const quote = await sdk.snxPerpsV3.quoteCollateralDebtPayment({
				amount: debtAmount.toBigInt(),
				token: depositableAssetToToken(asset),
				chainId: network,
			})

			dispatch(
				setSnxV3DebtPaymentQuote({
					quote: {
						amountIn: quote.quotedAmount.toString(),
						token: asset,
						amountOut: debtAmount.toString(),
						priceImpact: quote.priceImpact.toString(),
						quotedPrice: quote.priceAfter.toString(),
						indexPrice: price.toString(),
					},
				})
			)

			dispatch(setQueryStatus({ key: 'quote_debt_payment', status: FetchStatus.Success }))
		} catch (err) {
			dispatch(
				setQueryStatus({
					key: 'quote_debt_payment',
					status: FetchStatus.Error,
					error: 'Failed to fetch quote for debt payment',
				})
			)
			logError(err)
			notifyError('Failed to get quote', err)
			throw err
		}
	}
)

export const submitCrossMarginTradePanelOrder = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitCrossMarginTradePanelOrder',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const state = getState()
		const { provider } = selectAccountContext(state)
		const { nativeSizeDelta, stopLossPrice, takeProfitPrice, orderPrice } =
			selectTradePanelInputs(state)
		const market = selectV3SelectedMarketInfo(state)
		const accountId = selectSnxV3Account(state)
		const preview = selectCrossMarginTradePreview(state)
		const networkId = selectSnxV3Network(state)
		const marginEnginePermitted = selectMarginEnginePermitted(state)
		const orderType = selectTradeOrderType(state)
		const currentPrice = selectMarketIndexPrice(state)
		const orders = selectCrossMarginConditionalOrders(state)

		const side = selectLeverageSide(state)
		const marginEngineAddress = networkId
			? SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]
			: undefined

		try {
			if (!market || !accountId || !preview) throw new Error('Invalid order submission')
			if (!marginEngineAddress) throw new Error('Unsupported network')

			const order = serializeTransactionOrder({
				marketAsset: market.asset,
				newSize: preview.newSize.abs(),
				sizeDelta: preview.sizeDelta.abs(),
				type: orderType,
				side: side,
				price: preview.fillPrice,
			})

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
					step: 'submit-trade',
					order,
				})
			)

			// Remove stale orders
			await dispatch(removeStaleOrders())

			if (orderType === OrderTypeEnum.MARKET) {
				const request = await sdk.snxPerpsV3.submitOrder(
					{
						marketId: BigInt(market.marketId),
						accountId,
						sizeDelta: wei(nativeSizeDelta || 0),
						acceptablePrice: preview.desiredFillPrice,
						settlementStrategyId: market.settlementStrategies[0].strategyId,
						chainId: networkId,
					},
					{
						useEngine: marginEnginePermitted,
					}
				)

				await dispatch(
					submitFuturesTransaction({
						request,
						withKeeperCheck: true,
					})
				)

				dispatch(fetchSnxV3AccountData())
			} else {
				if (!orderPrice?.price) throw new Error('No price set for limit / stop order')

				const orderCondition = orderConditionFromTargetPrice(orderPrice.price, currentPrice)

				const existingOrder = orders.find((order) => {
					const orderConditionPrice = order.decodedConditions[orderCondition]
					const oPrice = orderPrice.price
					return (
						orderConditionPrice &&
						oPrice &&
						wei(orderConditionPrice).eq(oPrice) &&
						order.orderDetails.marketId === market.marketId &&
						order.status === ConditionalOrderStatusV3.Pending &&
						!wei(order.orderDetails.sizeDelta).abs().eq(SL_TP_MAX_SIZE_CROSS_MARGIN)
					)
				})

				const params = {
					marketId: market.marketId,
					networkId: networkId,
					accountId,
					isReduceOnly: false,
					sizeDelta: wei(nativeSizeDelta || 0).toBigInt(),
					acceptablePrice: preview.desiredFillPrice.toBigInt(),
					settlementStrategyId: market.settlementStrategies[0].strategyId,
					maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
					rawConditions: {
						[orderCondition]: orderPrice.price.toBigInt(),
					},
				}

				if (existingOrder) {
					// updateConditionalOrder with new sizeDelta (sum current into existing)
					await sdk.snxPerpsV3.updateConditionalOrders(
						[
							{
								orderId: existingOrder.id,
								nonce: existingOrder.nonce,
								inputs: {
									...params,
									sizeDelta: wei(nativeSizeDelta || 0)
										.add(existingOrder.orderDetails.sizeDelta)
										.toBigInt(),
								},
							},
						],
						networkId
					)
				} else {
					await sdk.snxPerpsV3.createConditionalOrders([params], networkId)
				}

				await dispatch(fetchOpenConditionalOrders([provider]))
			}

			const maxSizeDelta = nativeSizeDelta.gt(0)
				? SL_TP_MAX_SIZE_CROSS_MARGIN.neg()
				: SL_TP_MAX_SIZE_CROSS_MARGIN

			const sltpOrders: {
				type: RawCondition
				price: Wei
				acceptablePrice: Wei
				sizeDelta: Wei
				step: string
			}[] = []
			if (Number(stopLossPrice) > 0) {
				const desiredSLFillPrice = calculateDesiredFillPrice(
					maxSizeDelta,
					wei(stopLossPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
				)
				sltpOrders.push({
					type: nativeSizeDelta.gt(0) ? RawCondition.IsPriceBelow : RawCondition.IsPriceAbove,
					price: wei(stopLossPrice ?? 0),
					acceptablePrice: desiredSLFillPrice,
					sizeDelta: maxSizeDelta,
					step: 'sign-sl',
				})
			}

			if (Number(takeProfitPrice) > 0) {
				const desiredTPFillPrice = calculateDesiredFillPrice(
					maxSizeDelta,
					wei(takeProfitPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
				)
				sltpOrders.push({
					type: nativeSizeDelta.gt(0) ? RawCondition.IsPriceAbove : RawCondition.IsPriceBelow,
					price: wei(takeProfitPrice ?? 0),
					acceptablePrice: desiredTPFillPrice,
					sizeDelta: maxSizeDelta,
					step: 'sign-tp',
				})
			}
			if (sltpOrders.length > 0) {
				for (let i = 0; i < sltpOrders.length; i++) {
					const o = sltpOrders[i]
					dispatch(updateTransactionStep(o.step))
					await sdk.snxPerpsV3.createConditionalOrders(
						[
							{
								marketId: market.marketId,
								networkId: networkId,
								accountId,
								isReduceOnly: true,
								sizeDelta: o.sizeDelta.toBigInt(),
								acceptablePrice: o.acceptablePrice.toBigInt(),
								settlementStrategyId: market.settlementStrategies[0].strategyId,
								maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
								rawConditions: {
									[o.type]: o.price.toBigInt(),
								},
							},
						],
						networkId
					)
				}
			}
			await dispatch(fetchOpenConditionalOrders([provider]))
			dispatch(updateTransactionStep(undefined))
			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
			dispatch(fetchSnxV3AccountData())
			dispatch(setOpenModal(null))
			dispatch(clearCrossMarginTradeInputs())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			dispatch(updateTransactionStep(undefined))
			dispatch(setOpenModal(null))
			throw err
		}
	}
)

export const submitCrossMarginSLTPUpdates = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitCrossMarginSLTPUpdates',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { market, position } = selectEditPositionModalInfo(getState())
		const accountId = selectSnxV3Account(getState())
		const networkId = selectSnxV3Network(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		const existingOrders = selectAllSnxV3SLTPOrders(getState())
		const { stopLossPrice, takeProfitPrice } = selectSlTpModalInputs(getState())

		const marginEngineAddress = networkId
			? SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]
			: undefined

		try {
			if (!marginEngineAddress) throw new Error('Unsupported network')
			if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !accountId)
				throw new Error('Invalid order submission')
			if (!marginEnginePermitted) throw new Error('Margin engine not permitted')
			if (!position) throw new Error('No position for orders')

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
				})
			)

			const stopLoss = existingOrders.find(
				(o) => o.asset === market.asset && o.orderType === OrderTypeEnum.STOP
			)
			const takeProfit = existingOrders.find(
				(o) => o.asset === market.asset && o.orderType === OrderTypeEnum.LIMIT
			)

			const stopLossExists = !!stopLoss
			const takeProfitExists = !!takeProfit

			const stopLossPriceNotEmpty = stopLossPrice !== ''
			const takeProfitPriceNotEmpty = takeProfitPrice !== ''

			const stopLossChanged =
				(!stopLossExists && stopLossPriceNotEmpty) ||
				(stopLossExists && (stopLossPrice === '' || !stopLoss.targetPrice.eq(wei(stopLossPrice))))

			const takeProfitChanged =
				(!takeProfitExists && takeProfitPriceNotEmpty) ||
				(takeProfitExists &&
					(takeProfitPrice === '' || !takeProfit.targetPrice.eq(wei(takeProfitPrice))))

			const isLong = position?.details.side === PositionSide.LONG

			const maxSizeDelta = isLong ? SL_TP_MAX_SIZE_CROSS_MARGIN.neg() : SL_TP_MAX_SIZE_CROSS_MARGIN

			const sltpOrders: {
				updateOrder?: {
					orderId: number
					nonce: number
					status?: ConditionalOrderStatusV3
					inputs: ConditionalOrderInput
				}
				cancelOrder?: {
					orderId: number
					nonce: number
				}
				createOrder?: ConditionalOrderInput
			}[] = []

			const sharedParams = {
				marketId: market.marketId,
				networkId: networkId,
				accountId,
				isReduceOnly: true,
				sizeDelta: maxSizeDelta.toBigInt(),
				settlementStrategyId: market.settlementStrategies[0].strategyId,
				maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
			}

			if (stopLossChanged) {
				if (stopLossPriceNotEmpty) {
					const desiredSLFillPrice = calculateDesiredFillPrice(
						maxSizeDelta,
						wei(stopLossPrice || 0),
						wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
					)
					const conditionType = isLong ? RawCondition.IsPriceBelow : RawCondition.IsPriceAbove
					sltpOrders.push(
						stopLoss
							? {
									updateOrder: {
										orderId: stopLoss.id,
										nonce: stopLoss.nonce!,
										inputs: {
											...sharedParams,
											acceptablePrice: desiredSLFillPrice.toBigInt(),
											rawConditions: {
												[conditionType]: wei(stopLossPrice).toBigInt(),
											},
										},
									},
								}
							: {
									createOrder: {
										...sharedParams,
										acceptablePrice: desiredSLFillPrice.toBigInt(),
										rawConditions: {
											[conditionType]: wei(stopLossPrice).toBigInt(),
										},
									},
								}
					)
				} else {
					sltpOrders.push({
						cancelOrder: {
							orderId: stopLoss!.id,
							nonce: stopLoss!.nonce!,
						},
					})
				}
			}

			if (takeProfitChanged) {
				if (takeProfitPriceNotEmpty) {
					const desiredTPFillPrice = calculateDesiredFillPrice(
						maxSizeDelta,
						wei(takeProfitPrice || 0),
						wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
					)
					const conditionType = isLong ? RawCondition.IsPriceAbove : RawCondition.IsPriceBelow
					sltpOrders.push(
						takeProfit
							? {
									updateOrder: {
										orderId: takeProfit.id,
										nonce: takeProfit.nonce!,
										inputs: {
											...sharedParams,
											acceptablePrice: desiredTPFillPrice.toBigInt(),
											rawConditions: {
												[conditionType]: wei(takeProfitPrice).toBigInt(),
											},
										},
									},
								}
							: {
									createOrder: {
										...sharedParams,
										acceptablePrice: desiredTPFillPrice.toBigInt(),
										rawConditions: {
											[conditionType]: wei(takeProfitPrice).toBigInt(),
										},
									},
								}
					)
				} else {
					sltpOrders.push({
						cancelOrder: takeProfit?.nonce
							? {
									orderId: takeProfit?.id,
									nonce: takeProfit?.nonce,
								}
							: undefined,
					})
				}
			}

			const updates = sltpOrders.filter((o) => !!o.updateOrder)
			const creates = sltpOrders.filter((o) => !!o.createOrder)
			const cancels = sltpOrders.filter((o) => !!o.cancelOrder)

			if (sltpOrders.length > 0) {
				dispatch(
					setTransaction({
						chainId: networkId,
						status: TransactionStatus.AwaitingExecution,
						type: 'submit_cross_order',
						hash: null,
					})
				)

				if (updates.length > 0) {
					await sdk.snxPerpsV3.updateConditionalOrders(
						updates.map((o) => ({
							orderId: o.updateOrder!.orderId,
							nonce: o.updateOrder!.nonce,
							inputs: o.updateOrder!.inputs,
							status: o.updateOrder?.status,
						})),
						networkId
					)
				}
				if (cancels.length > 0) {
					await sdk.snxPerpsV3.cancelConditionalOrders(cancels.map((o) => o.cancelOrder!.orderId))
				}
				if (creates.length > 0) {
					await sdk.snxPerpsV3.createConditionalOrders(
						creates.map((o) => o.createOrder!),
						networkId
					)
				}
			}

			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
			dispatch(fetchSnxV3AccountData())
			if (market) {
				dispatch(editClosePositionSizeDelta(''))
				dispatch(editClosePositionPrice(''))
			}
			dispatch(setShowEditPositionModal(null))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const withdrawCMAccountKeeperBalance = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/withdrawAccountKeeperBalance',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const address = selectSnxV3Account(getState())
		const networkId = selectSnxV3Network(getState())
		try {
			if (!address) throw new Error('No cross margin account')
			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_keeper_balance',
					hash: null,
				})
			)

			const { request } = await sdk.snxPerpsV3.withdrawCreditForOrders(address, amount, networkId)
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				networkId
			)
			await monitorAndAwaitTransaction(networkId, dispatch, txHash)
			dispatch(fetchPerpsV3Balances())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const depositCMAccountKeeperBalance = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/depositAccountKeeperBalance',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const accountId = selectSnxV3Account(getState())
		const chainId = selectSnxV3Network(getState())

		try {
			if (!accountId) throw new Error('No cross margin account')

			dispatch(
				setTransaction({
					chainId,
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_keeper_balance',
					hash: null,
				})
			)

			const request = await sdk.snxPerpsV3.depositCreditForOrders({ accountId, amount, chainId })

			const tx = await sdk.transactions.sendTransaction(request, chainId)

			await monitorAndAwaitTransaction(chainId, dispatch, tx)
			dispatch(fetchPerpsV3Balances())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitCrossMarginReducePositionOrder = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitCrossMarginReducePositionOrder',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const networkId = selectSnxV3Network(getState())
		const { market, position, marketPrice } = selectEditPositionModalInfo(getState())
		const accountId = selectSnxV3Account(getState())
		const { nativeSizeDelta, orderType, price } = selectClosePositionOrderInputs(getState())
		const preview = selectClosePositionPreview(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		try {
			if (!position) throw new Error('Missing position data')
			if (!accountId) throw new Error('Account not found')
			if (!preview) throw new Error('Missing trade preview')
			if (market?.marginType !== FuturesMarginType.CROSS_MARGIN) throw new Error('Missing market')

			const order = serializeTransactionOrder({
				marketAsset: market.asset,
				newSize: preview.newSize.abs(),
				sizeDelta: preview.sizeDelta.abs(),
				type: orderType,
				side: preview.side,
				price: preview.fillPrice,
			})

			if (orderType !== OrderTypeEnum.MARKET) {
				if (!marginEnginePermitted) {
					await dispatch(grantMarginEnginePermission())
				}

				if (!price?.value) throw new Error('Missing price')
				const orderCondition = orderConditionFromTargetPrice(wei(price.value), marketPrice)

				await sdk.snxPerpsV3.createConditionalOrders(
					[
						{
							marketId: market.marketId,
							accountId,
							networkId,
							settlementStrategyId: market.settlementStrategies[0].strategyId,
							sizeDelta: wei(nativeSizeDelta).toBigInt(),
							acceptablePrice: preview.desiredFillPrice.toBigInt(),
							isReduceOnly: true,
							maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
							rawConditions: {
								[orderCondition]: wei(price.value).toBigInt(),
							},
						},
					],
					networkId
				)
			} else {
				dispatch(
					setTransaction({
						chainId: networkId,
						status: TransactionStatus.AwaitingExecution,
						type: 'close_cross_margin',
						hash: null,
						order,
					})
				)
				const request = await sdk.snxPerpsV3.submitOrder(
					{
						marketId: BigInt(market.marketId),
						accountId,
						sizeDelta: wei(nativeSizeDelta || 0),
						acceptablePrice: preview.desiredFillPrice,
						settlementStrategyId: market.settlementStrategies[0].strategyId,
						chainId: networkId,
					},
					{
						useEngine: marginEnginePermitted,
					}
				)

				await dispatch(
					submitFuturesTransaction({
						request,
					})
				)
			}

			dispatch(fetchPendingMarketOrders([market.provider]))
			dispatch(fetchSnxV3AccountData())
			if (market) {
				dispatch(editClosePositionSizeDelta(''))
				dispatch(editClosePositionPrice(''))
			}
			dispatch(setShowEditPositionModal(null))
			dispatch(clearCrossMarginTradeInputs())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
		// TODO: hookup submit v3 order
	}
)

export const executeAsyncOrder = createAsyncThunk<void, `0x${string}`, ThunkConfig>(
	'futures/executeAsyncOrder',
	async (originTxHash, { getState, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV3AccountContext(getState())
		if (!accountId) throw new Error('No wallet connected')

		const request = await sdk.snxPerpsV3.executeAsyncOrder(BigInt(accountId), network)
		const txHash = await sdk.transactions.sendTransaction(request, network)
		await monitorFollowingTransaction(originTxHash, txHash, network)
	}
)
createAsyncThunk<void, { asset: DepositableAssetKeysV3Type }, ThunkConfig>(
	'futures/approveSpotMarketSpender',
	async ({ asset }, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectV3SelectedMarketInfo(getState())
		const network = selectSnxV3Network(getState())

		const spotMarketAddress = network
			? SNX_V3_PERPS_ADDRESSES.SpotV3MarketProxy[network]
			: undefined
		if (!spotMarketAddress) throw new Error('No spot market address found')
		if (!marketInfo) throw new Error('Market info not found')
		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'approve_cross_margin',
					hash: null,
				})
			)

			const tokenAddress = COMMON_ADDRESSES[depositableAssetToToken(asset)][network]
			if (!tokenAddress) throw new Error('Token address not found')
			const { request } = await sdk.tokens.approveTokenSpend({
				address: tokenAddress,
				spender: spotMarketAddress,
				chainId: network,
			})
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(fetchPerpsV3Balances())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)
createAsyncThunk<void, { asset: 'USDC' | 'USDx' }, ThunkConfig>(
	'futures/approvePerpsMarketSpender',
	async ({ asset }, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectV3SelectedMarketInfo(getState())
		const network = selectSnxV3Network(getState())

		const perpsMarketAddress = network
			? SNX_V3_PERPS_ADDRESSES.PerpsV3MarketProxy[network]
			: undefined
		if (!perpsMarketAddress) throw new Error('No perps proxy address found')
		if (!marketInfo) throw new Error('Market info not found')
		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'approve_cross_margin',
					hash: null,
				})
			)
			const tokenAddress = COMMON_ADDRESSES[asset][network]
			if (!tokenAddress) throw new Error('Token address not found')
			const { request } = await sdk.tokens.approveTokenSpend({
				address: tokenAddress,
				spender: perpsMarketAddress,
				chainId: network,
			})
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(fetchPerpsV3Balances())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)
export const editCrossMarginTradeOrderPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const rate = selectV3SkewAdjustedPrice(getState())
		const orderType = selectTradeOrderType(getState())
		const side = selectLeverageSide(getState())
		const inputs = selectTradePanelInputs(getState())
		dispatch(setTradePanelOrderPrice(price))
		const invalidLabel = orderPriceInvalidLabel(price, side, rate, orderType)
		dispatch(setOrderPriceInvalidLabel(invalidLabel))
		if (!invalidLabel && price && inputs.susdSize) {
			// Recalc the trade
			dispatch(editCrossMarginTradeSize(inputs.susdSizeString, 'usd'))
		}
	}

export const editCrossMarginPositionSize =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		const { marketPrice, market, indexPrice, accountId } = selectEditPositionModalInfo(getState())
		if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !indexPrice || !accountId)
			throw new Error('Missing market data')
		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market,
					currentIndexPrice: marketPrice,
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'edit',
					indexPrice,
					marketPrice: marketPrice,
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editCrossMarginConditionalOrder =
	(order: ConditionOrderTableItem, newSize: string, newPrice: string): AppThunk =>
	(dispatch, getState) => {
		const markets = selectV3Markets(getState())
		const prices = selectPrices(getState())
		const accountId = selectSnxV3Account(getState())

		const market = markets.find((m) => m.asset === order.asset)

		if (!market) throw new Error('Missing market data')
		if (!accountId) throw new Error('Missing account data')

		const price = prices[market.asset]
		const marketPrice = price?.offChain
			? wei(price.offChain).mul(wei(market.marketSkew).div(market.settings.skewScale).add(1))
			: ZERO_WEI

		if (!market || !price.offChain) throw new Error('Missing price data')
		try {
			dispatch(
				stageTradePreview({
					accountId: accountId.toString(),
					provider: market.provider,
					market,
					orderPrice: wei(newPrice || 0),
					currentIndexPrice: marketPrice,
					sizeDelta: wei(newSize || 0),
					action: 'edit',
					indexPrice: price.offChain,
					marketPrice: marketPrice,
					isConditional: true,
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editCrossMarginConditionalOrderPrice =
	(order: ConditionOrderTableItem, price: string): AppThunk =>
	(dispatch, getState) => {
		const rate = selectV3SkewAdjustedPrice(getState())
		const side = order.tradeDirection
		const { size } = selectEditCOModalInputs(getState())

		dispatch(setEditConditonalOrderModalPrice(price))
		const invalidLabel = orderPriceInvalidLabel(price, side, rate, order.orderType)
		dispatch(setConditionalOrderPriceInvalidLabel(invalidLabel))
		dispatch(editCrossMarginConditionalOrder(order, size || '0', price))
	}

export const editCrossMarginConditionalOrderSize =
	(order: ConditionOrderTableItem, size: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setEditConditonalOrderModalSize(size))
		const { orderPrice } = selectEditCOModalInputs(getState())

		dispatch(editCrossMarginConditionalOrder(order, size, orderPrice.price || '0'))
	}

export const submitCrossMarginAdjustPositionSize = createAsyncThunk<void, boolean, ThunkConfig>(
	'futures/submitCrossMarginAdjustPositionSize',
	async (overridePriceProtection, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market } = selectEditPositionModalInfo(state)
		const account = selectSnxV3Account(state)
		const chainId = selectSnxV3Network(state)
		const preview = selectEditPositionPreview(state)
		const { nativeSizeDelta } = selectEditPositionInputs(state)
		const marginEnginePermitted = selectMarginEnginePermitted(state)

		try {
			if (market?.marginType !== FuturesMarginType.CROSS_MARGIN)
				throw new Error('Market info not found')
			if (!account) throw new Error('No account found')
			if (!nativeSizeDelta || nativeSizeDelta === '') throw new Error('No margin amount set')
			if (!preview) throw new Error('Missing trade preview')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const settlementStrategyId = market.settlementStrategies[0]?.strategyId
			if (!settlementStrategyId && settlementStrategyId !== 0)
				throw new Error('No settlement strategy found')

			const order = serializeTransactionOrder({
				marketAsset: market.asset,
				newSize: preview.newSize.abs(),
				sizeDelta: preview.sizeDelta.abs(),
				type: OrderTypeEnum.MARKET,
				side: preview.side,
				price: preview.fillPrice,
			})

			dispatch(
				setTransaction({
					chainId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
					order,
				})
			)

			const request = await sdk.snxPerpsV3.submitOrder(
				{
					accountId: account,
					marketId: BigInt(market.marketId),
					sizeDelta: wei(nativeSizeDelta),
					acceptablePrice: preview.desiredFillPrice,
					settlementStrategyId: settlementStrategyId,
					chainId,
				},
				{
					useEngine: marginEnginePermitted,
				}
			)

			await dispatch(
				submitFuturesTransaction({
					request,
				})
			)

			dispatch(setShowEditPositionModal(null))
			dispatch(clearCrossMarginTradeInputs())
			dispatch(fetchSnxV3AccountData())
			dispatch(clearCrossMarginTradeInputs())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const editCloseCMPositionPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const state = getState()
		const { nativeSizeDelta, orderType } = selectClosePositionOrderInputs(state)
		const { position, marketPrice, market, indexPrice, accountId } =
			selectEditPositionModalInfo(state)

		if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !indexPrice || !accountId)
			throw new Error('No market or account data')

		const closeTradeSide =
			position?.details.side === PositionSide.SHORT ? PositionSide.LONG : PositionSide.SHORT
		const invalidLabel = orderPriceInvalidLabel(
			price || '0',
			closeTradeSide,
			marketPrice,
			orderType
		)

		dispatch(setClosePositionPrice({ value: price, invalidLabel }))

		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market: market,
					orderPrice: Number.isNaN(Number(price)) ? undefined : wei(price),
					currentIndexPrice: marketPrice,
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'close',
					isConditional: orderType !== OrderTypeEnum.MARKET,
					indexPrice,
					marketPrice: marketPrice,
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: market.provider,
				})
			)
		}
	}

export const fetchUsdcAbstractionAllowanceForEngine = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchUsdcAbstractionAllowanceForEngine',
	async (_, { dispatch, getState, extra: { sdk, accountAbstractionFactory } }) => {
		const { network, provider } = selectAccountContext(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const { accountAddress } = accountAbstraction || {}
		if (!accountAddress) throw new Error('No account abstraction address')

		const engineAddress = SNX_V3_PERPS_ADDRESSES.MarginEngine[network as SnxV3NetworkIds]
		const tokenAddress = COMMON_ADDRESSES.USDC[network]
		if (!tokenAddress || !engineAddress) throw new Error('Unsupported network')
		const allowance = await sdk.tokens.checkAllowance(
			tokenAddress,
			engineAddress,
			network,
			accountAddress
		)

		dispatch(setAbstractionUsdcEngineAllowance(allowance.toString()))
	}
)

export const fetchMaxDepositAmounts = createAsyncThunk<void, void, ThunkConfig>(
	'futures/maxDepositAmounts',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const network = selectSnxV3Network(getState())
		const provider = selectPerpsProvider(getState())
		if (!providerIsCrossMargin) return
		try {
			const assets =
				provider === PerpsProvider.SNX_V3_ARB
					? Object.keys(DepositableV3AssetsArb)
					: [DepositableV3AssetsBase.USDC]

			const maxDepositAmounts = await sdk.snxPerpsV3.getMaxDepositAmounts(
				assets as DepositableV3Assets[],
				network
			)
			const noLimitSnxUsdDeposit = '100000000000'
			dispatch(
				setSnxV3MaxDepositAmounts({
					maxDeposits: { ...maxDepositAmounts, USDx: noLimitSnxUsdDeposit },
					provider,
				})
			)
		} catch (err) {
			logError(err)
			throw err
		}
	}
)
