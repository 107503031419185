import { HermesClient } from '@pythnetwork/hermes-client';
import { decodeAbiParameters, encodeAbiParameters } from 'viem';
import { notNill } from '../../utils';
export class PythAdapter {
    constructor(endpoint) {
        this.connection = new HermesClient(endpoint);
    }
    getOracleId() {
        return 'PYTH';
    }
    async fetchOffchainData(oracleQueries, extraPriceIds = []) {
        const decodedQueries = oracleQueries.map((q) => ({
            query: decodeAbiParameters([{ name: 'updateType', type: 'uint8' }], q),
            data: q,
        }));
        const queries = decodedQueries.reduce((acc, { query: [updateType], data }) => {
            if (updateType === 1) {
                const [_acceptablePriceupdateType, stalenessOrTime, priceIds] = decodeAbiParameters([
                    { name: 'updateType', type: 'uint8' },
                    { name: 'stalenessTolerance', type: 'uint64' },
                    { name: 'priceIds', type: 'bytes32[]' },
                ], data);
                const ids = [
                    ...new Set([
                        ...(acc[1][stalenessOrTime.toString()] ?? []),
                        ...priceIds,
                        ...extraPriceIds,
                    ]),
                ].filter(notNill);
                acc[1][stalenessOrTime.toString()] = ids;
            }
            else if (updateType === 2) {
                const [_updateType, requestedTime, priceIds] = decodeAbiParameters([
                    { name: 'updateType', type: 'uint8' },
                    { name: 'requestedTime', type: 'uint64' },
                    { name: 'priceIds', type: 'bytes32' },
                ], data);
                const ids = [
                    ...new Set([
                        ...(acc[2][requestedTime.toString()] ?? []),
                        ...priceIds,
                        ...extraPriceIds,
                    ]),
                ].filter(notNill);
                acc[2][requestedTime.toString()] = ids;
            }
            return acc;
        }, { 1: {}, 2: {} });
        const updateTypes = Object.keys(queries);
        const calls = [];
        for (const updateType of updateTypes) {
            const key = updateType;
            const updateItems = Object.keys(queries[key]);
            for (const stalenessOrTime of updateItems) {
                const priceIds = queries[key][stalenessOrTime];
                const updateDataResponse = await this.connection.getLatestPriceUpdates(priceIds);
                const updateData = updateDataResponse.binary.data.map((x) => `0x${x}`);
                calls.push({
                    data: encodePriceUpdateData(updateData, priceIds, BigInt(stalenessOrTime), Number(key)),
                    ids: priceIds,
                });
            }
        }
        return calls;
    }
}
export const encodePriceUpdateData = (updateData, ids, timestampOrTolerance, updateType) => {
    if (updateType === 2) {
        return encodeAbiParameters([
            { type: 'uint8', name: 'updateType' },
            { type: 'uint64', name: 'timestamp' },
            { type: 'bytes32[]', name: 'priceIds' },
            { type: 'bytes[]', name: 'updateData' },
        ], [updateType, timestampOrTolerance, ids, updateData]);
    }
    else {
        return encodeAbiParameters([
            { type: 'uint8', name: 'updateType' },
            { type: 'uint64', name: 'stalenessTolerance' },
            { type: 'bytes32[]', name: 'priceIds' },
            { type: 'bytes[]', name: 'updateData' },
        ], [updateType, timestampOrTolerance, ids, updateData]);
    }
};
